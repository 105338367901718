import { Box, Button, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PageMode } from '../SelectCheckoutShippingMethods';
import ShippingMethodSelector from './ShippingMethodSelector';

export interface CheckoutShippingFormProps {
	upsShippingMethod: number;
	setUpsShippingMethod: (value: any | null) => void;
	fedexShippingMethod: number;
	setFedExShippingMethod: (value: any | null) => void;
	upsCarrierSelected: boolean;
	setUpsCarrierSelected: (value: any | null) => void;
	fedexCarrierSelected: boolean;
	setFedExCarrierSelected: (value: any | null) => void;
	tunlCarrierSelected: boolean;
	setTunlCarrierSelected: (value: any | null) => void;
	disabled: boolean;
	pageMode: PageMode;
	onCancelHandler: (value: any | null) => void;
	onSaveHandler: (value: any | null) => void;
	onEditHandler: (value: any | null) => void;
	initCheck: boolean;
}

export default function CheckoutShippingForm(props: CheckoutShippingFormProps) {
	const {
		upsShippingMethod,
		setUpsShippingMethod,
		fedexShippingMethod,
		setFedExShippingMethod,
		upsCarrierSelected,
		setUpsCarrierSelected,
		fedexCarrierSelected,
		setFedExCarrierSelected,
		tunlCarrierSelected,
		setTunlCarrierSelected,
		disabled,
		pageMode,
		onCancelHandler,
		onSaveHandler,
		onEditHandler,
		initCheck
	} = props;
	const {
		register,
		unregister,
		getValues,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
		watch
	} = useForm({ mode: 'all' });

	const onUPSShippingMethodChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setValue('upssaver_method', event.target.value);
		setUpsShippingMethod(Number(event.target.value));
	};

	const onFedexShippingMethodChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setValue('fedex_international_priority_method', event.target.value);
		setFedExShippingMethod(Number(event.target.value));
	};

	const onUPSSelectChangeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue('upssaver', event.target.checked);
		setUpsCarrierSelected(event.target.checked);
	};

	const onFedexSelectChangeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue('fedex_international_priority', event.target.checked);
		setFedExCarrierSelected(event.target.checked);
	};

	const onTunlSelectChangeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue('ground', event.target.checked);
		setTunlCarrierSelected(event.target.checked);
	};

	// Watch checkbox values
	const economyCheckBox = watch('ground');
	const fedexDDPCheckBox = watch('fedex_international_priority');
	const upsDDPCheckBox = watch('upssaver');

	useEffect(() => {
		const formData = getValues();
		if (economyCheckBox || fedexDDPCheckBox || upsDDPCheckBox) {
			clearErrors('checkboxes');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [economyCheckBox, fedexDDPCheckBox, upsDDPCheckBox]);

	const handleSubmitV2 = async () => {
		const formData = getValues();
		console.log(JSON.stringify(formData, null, 4));
	};

	return (
		<Grid container direction="column" justifyContent="space-between" sx={{ height: '100%', pr: 2 }}>
			<Grid item>
				<Typography variant="h6" sx={{ flex: 1 }}>
					Please select which services to add to your checkout.
				</Typography>
				<FormGroup>
					<ShippingMethodSelector
						label={'TUNL Economy [Duties & Taxes Included]'}
						checkboxName="ground"
						selected={tunlCarrierSelected}
						onSelect={onTunlSelectChangeChangeHandler}
						register={register}
						errors={errors}
						hasOptions={false}
						disabled={disabled}
					/>
					<ShippingMethodSelector
						label="FedEx International Priority"
						checkboxName="fedex_international_priority"
						methodName="fedex_international_priority_method"
						methodValue={fedexShippingMethod}
						selected={fedexCarrierSelected}
						onChange={onFedexShippingMethodChangeHandler}
						onSelect={onFedexSelectChangeChangeHandler}
						register={register}
						errors={errors}
						hasOptions={true}
						disabled={disabled}
					/>
					<ShippingMethodSelector
						label="UPS Express Saver"
						checkboxName="upssaver"
						methodName="upssaver_method"
						methodValue={upsShippingMethod}
						selected={upsCarrierSelected}
						onChange={onUPSShippingMethodChangeHandler}
						onSelect={onUPSSelectChangeChangeHandler}
						register={register}
						errors={errors}
						hasOptions={true}
						disabled={disabled}
					/>
				</FormGroup>
			</Grid>
			<Grid item sx={{ alignSelf: 'flex-end', mt: 'auto' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						flexDirection: 'row',
						gap: 1
					}}
				>
					{pageMode === PageMode.WRITE || initCheck ? (
						<>
							<Button variant="contained" onClick={onCancelHandler} color="primary">
								<Typography>Cancel</Typography>
							</Button>
							<Button variant="contained" onClick={onSaveHandler} color="primary">
								<Typography>Save</Typography>
							</Button>
						</>
					) : (
						<Button variant="contained" onClick={onEditHandler} color="primary">
							<Typography>Edit</Typography>
						</Button>
					)}
				</Box>
			</Grid>
		</Grid>
	);
}
