import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	Checkbox,
	Dialog,
	FormControlLabel,
	Grid,
	Link,
	Paper,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import AddressAutoComplete from './AddressAutoComplete';
import FlyerBagModal from './FlyerBagModal';
import { ACCORDION, AUTOCOMPLETE, CHECKBOX, CREATE_SHIPMENTS, FORM_CONTROL_LABEL, HEADING, KEY, LABEL, PARCEL_INFO, SEND_FROM, SHIPMENT_DETAILS, TEXTFIELD, TO_ADDRESS, VALUE, createElementId } from '../../../../constants/id';
import LearnMore from '../../../../components/Common/LearnMore'

export default function ShipmentDetailsForm(props: any) {
	const isToAddressFilled = () => {
		return (
			props.to_address?.address_to_street_1?.trim() !== '' ||
			props.to_address?.address_to_city_locality?.trim() !== '' ||
			props.to_address?.address_to_state_province?.trim() !== '' ||
			props.to_address?.address_to_zip_postal?.trim() !== '' ||
			props.to_address?.address_to_country?.trim() !== ''
		);
	};
	const isAddressFormFilled = isToAddressFilled();

	const { watch, setValue, getValues, setError, clearErrors, expanded, setExpanded } = props;
	const [volumetric_weight, setVolumetricWeight] = useState(0);
	const [volError, setVolError] = useState('');
	const checkWeight = volumetric_weight > props.watch('parcel_details.parcel_weight_kg');
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('lg'));
	const [isOpen, SetCheckbox] = useState(false);
	const [openModal, SetCloseModal] = useState(false);
	const addressId = createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, AUTOCOMPLETE, 'address']);

	useEffect(() => {
		const parcelDimensions = getValues('parcel_details');
		if (
			parcelDimensions &&
			parcelDimensions.parcel_length_cm &&
			parcelDimensions.parcel_width_cm &&
			parcelDimensions.parcel_height_cm
		) {
			setVolumetricWeight(
				(parcelDimensions.parcel_length_cm *
					parcelDimensions.parcel_width_cm *
					parcelDimensions.parcel_height_cm) /
					5000
			);
			setValue('parcel_details.volumetric_weight', volumetric_weight);
		} else {
			setVolumetricWeight(0);
		}
		if (volumetric_weight > 70 || props.watch('parcel_details.parcel_weight_kg') > 70) {
			setVolError('For Chargeable weight greater than 70kg, please contact support@tunl.to');
			setError('volumetric_weight', {
				type: 'custom',
				message: 'For Chargeable weight greater than 70kg, please contact support@tunl.to'
			});
		} else {
			setVolError('');
			clearErrors('volumetric_weight');
		}
	}, [
		watch('parcel_details.parcel_height_cm'),
		watch('parcel_details.parcel_length_cm'),
		watch('parcel_details.parcel_width_cm'),
		watch('parcel_details.parcel_weight_kg'),
		volumetric_weight
	]);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const validateChargeableWeight = (weight: number) => {
		if (volumetric_weight !== 0) {
			if (volumetric_weight > weight) {
				return 'Volumetric weight is greater than actual weight';
			} else {
				return true;
			}
		}
		return true;
	};

	const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isOpen) {
			SetCheckbox(false);
			SetCloseModal(false);
		} else {
			SetCheckbox(true);
			SetCloseModal(true);
		}
	};

	return (
        // make this fragment take up full width of parent component
        (<>
            <Grid container direction="column" alignContent="center" justifyContent="center" lineHeight={5}>
				<Typography 
					id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS,HEADING, LABEL, SHIPMENT_DETAILS])}
					variant="h5" mb={5} mt={5} textAlign={'center'}>
					Shipment Details
				</Typography>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Accordion 
					id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION, SEND_FROM])}
					expanded={expanded === 'from_address'} onChange={handleChange('from_address')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography
								id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM , LABEL, SEND_FROM])}
								sx={{ width: '33%', flexShrink: 0 }}>Send From:</Typography>
							<Typography
							id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, LABEL, 'full_address'])} 
								sx={{ color: 'text.secondary' }}>{`${watch(
								'from_address.address_from_street_1'
							)}, ${watch('from_address.address_from_city_locality')}, ${watch(
								'from_address.address_from_state_province'
							)}, ${watch('from_address.address_from_zip_postal')}, ${watch(
								'from_address.address_from_country'
							)}`}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container direction={'column'} mt={3}>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'fullname'])} 
												fullWidth
												required
												label="Full Name"
												{...props.register('from_address.address_from_name', {
													required: 'Required'
												})}
												error={!!props.errors?.from_address?.address_from_name}
												helperText={
													props.errors?.from_address?.address_from_name
														? (props.errors.from_address.address_from_name
																.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'company'])} 
												label="Company"
												fullWidth
												{...props.register('from_address.address_from_company')}
												error={!!props.errors?.from_address?.address_from_company}
												helperText={
													props.errors?.from_address?.address_from_company
														? (props.errors.from_address.address_from_company
																.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'email'])} 
												required
												label="Email"
												fullWidth
												{...props.register('from_address.address_from_email', {
													required: 'Required',
													pattern: {
														value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
														message: 'Invalid email address'
													}
												})}
												value={props.watch('from_address.address_from_email').trim()}
												error={!!props.errors?.from_address?.address_from_email}
												helperText={
													props.errors?.from_address?.address_from_email
														? (props.errors.from_address.address_from_email
																.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'phone'])} 
												required
												label="Phone"
												fullWidth
												{...props.register('from_address.address_from_phone', {
													required: 'Required',
													pattern: {
														value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
														message: 'Invalid phone number'
													}
												})}
												value={props.watch('from_address.address_from_phone').trim()}
												error={!!props.errors?.from_address?.address_from_phone}
												helperText={
													props.errors?.from_address?.address_from_phone
														? (props.errors.from_address.address_from_phone
																.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
											<AddressAutoComplete
												id={addressId + 'fromAddress'} 
												setFromAddressDetails={props.setFromAddressDetails}
												shipment_type={props.shipment_type}
											></AddressAutoComplete>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'address_line_1'])}
												required
												label="Address Line 1"
												fullWidth
												{...props.register('from_address.address_from_street_1', {
													required: 'Required'
												})}
												error={!!props.errors?.from_address?.address_from_street_1}
												helperText={
													props.errors?.from_address?.address_from_street_1
														? (props.errors.from_address.address_from_street_1
																.message as string)
														: null
												}
												value={props.watch('from_address.address_from_street_1') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4} mb={2}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'address_line_2'])}
												fullWidth
												label="Address Line 2"
												{...props.register('from_address.address_from_street_2')}
												error={!!props.errors?.from_address?.address_from_street_2}
												helperText={
													props.errors?.from_address?.address_from_street_2
														? (props.errors.from_address.address_from_street_2
																.message as string)
														: 'Apartment, building, floor(optional)'
												}
												value={props.watch('from_address.address_from_street_2') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={3} lg={2} xl={2}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'city'])}
												required
												label="City"
												fullWidth
												{...props.register('from_address.address_from_city_locality', {
													required: 'Required'
												})}
												error={!!props.errors?.from_address?.address_from_city_locality}
												helperText={
													props.errors?.from_address?.address_from_city_locality
														? (props.errors.from_address.address_from_city_locality
																.message as string)
														: null
												}
												value={props.watch('from_address.address_from_city_locality') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'province'])}
												required
												label="State/Province"
												fullWidth
												{...props.register('from_address.address_from_state_province', {
													required: 'Required',
													pattern: {
														value: /^[a-z][A-Z]{1}$/i,
														message: 'Invalid input. Province must be a 2 letter code'
													}
												})}
												error={!!props.errors?.from_address?.address_from_state_province}
												helperText={
													props.errors?.from_address?.address_from_state_province
														? (props.errors.from_address.address_from_state_province
																.message as string)
														: null
												}
												value={props.watch('from_address.address_from_state_province') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={3} lg={2} xl={2}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'postal_code'])}
												required
												fullWidth
												label="Zip/Postal Code"
												{...props.register('from_address.address_from_zip_postal', {
													required: 'Required'
												})}
												error={!!props.errors?.from_address?.address_from_zip_postal}
												helperText={
													props.errors?.from_address?.address_from_zip_postal
														? (props.errors.from_address.address_from_zip_postal
																.message as string)
														: null
												}
												value={props.watch('from_address.address_from_zip_postal') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,SEND_FROM, TEXTFIELD, 'country_code'])}
												required
												fullWidth
												label="Country (2 letter code)"
												{...props.register('from_address.address_from_country', {
													required: 'Required',
													pattern: {
														value: /^[a-z][A-Z]{1}$/i,
														message: 'Invalid input. Country must be a 2 letter code'
													}
												})}
												error={!!props.errors?.from_address?.address_from_country}
												helperText={
													props.errors?.from_address?.address_from_country
														? (props.errors.from_address.address_from_country
																.message as string)
														: null
												}
												value={props.watch('from_address.address_from_country') || ''}
												onChange={props.handleAddressChange}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Accordion 
						id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION, TO_ADDRESS])}
						expanded={expanded === 'to_address'} 
						onChange={handleChange('to_address')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							<Typography
							id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS , LABEL, TO_ADDRESS])}
							sx={{ width: '33%', flexShrink: 0 }}>Send To:</Typography>
							{isAddressFormFilled && (
								<Typography sx={{ color: 'text.secondary' }}>
									{`
										${watch('to_address.address_to_street_2')}
										${watch('to_address.address_to_street_1')},
										${watch('to_address.address_to_city_locality')},
										${watch('to_address.address_to_state_province')},
										${watch('to_address.address_to_zip_postal')},
										${watch('to_address.address_to_country')}
									`}
								</Typography>
							)}
						</AccordionSummary>
						<AccordionDetails>
							<Grid container direction={'column'} mt={3}>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'fullname'])} 										
												fullWidth
												required
												label="Full Name"
												autoFocus
												{...props.register('to_address.address_to_name', {
													required: 'Required'
												})}
												error={!!props.errors?.to_address?.address_to_name}
												helperText={
													props.errors?.to_address?.address_to_name
														? (props.errors.to_address.address_to_name.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'company'])}
												label="Company"
												fullWidth
												{...props.register('to_address.address_to_company')}
												error={!!props.errors?.to_address?.address_to_company}
												helperText={
													props.errors?.to_address?.address_to_company
														? (props.errors.to_address.address_to_company.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<TextField
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'email'])}
												required
												label="Email"
												fullWidth
												{...props.register('to_address.address_to_email', {
													required: 'Required',
													pattern: {
														value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
														message: 'Invalid email address'
													}
												})}
												value={props.watch('to_address.address_to_email').trim()}
												error={!!props.errors?.to_address?.address_to_email}
												helperText={
													props.errors?.to_address?.address_to_email
														? (props.errors.to_address.address_to_email.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<Tooltip
												title={
													'Example format: +12345678911. Format with extension: +12345678911#12345'
												}
											>
												{/* <InfoIcon sx={{ color: '#9c9c9c' }}></InfoIcon> */}
												<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'phone'])}
													required
													label="Phone"
													fullWidth
													{...props.register('to_address.address_to_phone', {
														required: 'Required',
														pattern: {
															value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}?([#][0-9]{2,5})?$/im,
															message: 'Invalid phone number'
														}
													})}
													value={props.watch('to_address.address_to_phone').trim()}
													error={!!props.errors?.to_address?.address_to_phone}
													helperText={
														props.errors?.to_address?.address_to_phone
															? (props.errors.to_address.address_to_phone
																	.message as string)
															: null
													}
													inputProps={{ 'data-hj-allow': true }}
												/>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container spacing={2} justifyContent="center" alignContent="center">
										<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
											<AddressAutoComplete
												addressId={addressId + 'toAddress'} 
												setToAddressDetails={props.setToAddressDetails}
												shipment_type={props.shipment_type}
											></AddressAutoComplete>
										</Grid>
									</Grid>
								</Grid>
								{isAddressFormFilled && (
									<>
										<Grid item>
											<Grid container spacing={2} justifyContent="center" alignContent="center">
												<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
													<TextField
														id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'address_line_1'])}
														required
														label="Address Line 1"
														fullWidth
														{...props.register('to_address.address_to_street_1', {
															required: 'Required',
															validate: {
																isPOBox: (value: string) => {
																	if (value) {
																		const poBoxRegex =
																			/\b[P|p]\.?\s*[O|o]\.?\s*[B|b][O|o|0][X|x]\b/;
																		if (poBoxRegex.test(value)) {
																			return 'PO Box addresses are not allowed. Please provide a physical address.';
																		}
																	}
																	return true;
																}
															}
														})}
														error={!!props.errors?.to_address?.address_to_street_1}
														helperText={
															props.errors?.to_address?.address_to_street_1
																? (props.errors.to_address.address_to_street_1
																		.message as string)
																: null
														}
														value={props.watch('to_address.address_to_street_1') || ''}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
												<Grid item xs={10} sm={10} md={5} lg={4} xl={4} mb={2}>
													<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'address_line_2'])}
														fullWidth
														label="Address Line 2"
														{...props.register('to_address.address_to_street_2', {
															validate: {
																isPOBox: (value: string) => {
																	if (value) {
																		const poBoxRegex =
																			/\b[P|p]\.?\s*[O|o]\.?\s*[B|b][O|o|0][X|x]\b/;
																		if (poBoxRegex.test(value)) {
																			return 'PO Box addresses are not allowed. Please provide a physical address.';
																		}
																	}
																	return true;
																}
															}
														})}
														error={!!props.errors?.to_address?.address_to_street_2}
														helperText={
															props.errors?.to_address?.address_to_street_2
																? (props.errors.to_address.address_to_street_2
																		.message as string)
																: 'Apartment, building, floor (optional)'
														}
														value={props.watch('to_address.address_to_street_2') || ''}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Grid container spacing={2} justifyContent="center" alignContent="center">
												<Grid item xs={10} sm={10} md={3} lg={2} xl={2}>
													<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'city'])}
														required
														label="City"
														fullWidth
														{...props.register('to_address.address_to_city_locality', {
															required: 'Required'
														})}
														error={!!props.errors?.to_address?.address_to_city_locality}
														helperText={
															props.errors?.to_address?.address_to_city_locality
																? (props.errors.to_address.address_to_city_locality
																		.message as string)
																: null
														}
														value={props.watch('to_address.address_to_city_locality') || ''}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
												<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
													<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'province'])}
														required
														label="State/Province"
														fullWidth
														{...props.register('to_address.address_to_state_province', {
															required: 'Required'
														})}
														error={!!props.errors?.to_address?.address_to_state_province}
														helperText={
															props.errors?.to_address?.address_to_state_province
																? (props.errors.to_address.address_to_state_province
																		.message as string)
																: null
														}
														value={
															props.watch('to_address.address_to_state_province') || ''
														}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
												<Grid item xs={10} sm={10} md={3} lg={2} xl={2}>
													<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'postal_code'])}
														required
														fullWidth
														label="Zip/Postal Code"
														{...props.register('to_address.address_to_zip_postal', {
															required: 'Required'
														})}
														error={!!props.errors?.to_address?.address_to_zip_postal}
														helperText={
															props.errors?.to_address?.address_to_zip_postal
																? (props.errors.to_address.address_to_zip_postal
																		.message as string)
																: null
														}
														value={props.watch('to_address.address_to_zip_postal') || ''}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
												<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
													<TextField
													id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,TO_ADDRESS, TEXTFIELD, 'country_code'])}
														required
														fullWidth
														label="Country"
														{...props.register('to_address.address_to_country', {
															required: 'Required',
															pattern: {
																value: /^[a-z][A-Z]{1}$/i,
																message: 'Invalid input. Country must be 2 letter code'
															}
														})}
														error={!!props.errors?.to_address?.address_to_country}
														helperText={
															props.errors?.to_address?.address_to_country
																? (props.errors.to_address.address_to_country
																		.message as string)
																: null
														}
														value={props.watch('to_address.address_to_country') || ''}
														onChange={props.handleAddressChange}
														inputProps={{ 'data-hj-allow': true }}
													/>
												</Grid>
											</Grid>
										</Grid>
									</>
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Accordion 
					id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION, PARCEL_INFO])}
					expanded={expanded === 'parcel_details'} 
					onChange={handleChange('parcel_details')}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel3a-content"
							id="panel3a-header"
						>
							<Typography 
								id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO , LABEL, PARCEL_INFO])}
								sx={{ width: '33%', flexShrink: 0 }}>Parcel Info:</Typography>
							<Typography 
								id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO , LABEL, 'parcel_info_summary'])}
								sx={{ color: 'text.secondary' }}>
								{watch('parcel_details.parcel_length_cm') &&
								watch('parcel_details.parcel_width_cm') &&
								watch('parcel_details.parcel_height_cm') &&
								watch('parcel_details.parcel_weight_kg')
									? `L: ${watch('parcel_details.parcel_length_cm').toFixed(2)}cm, W: ${watch(
											'parcel_details.parcel_width_cm'
									  ).toFixed(2)}cm, H: ${watch('parcel_details.parcel_height_cm').toFixed(
											2
									  )}cm, W: ${
											checkWeight
												? volumetric_weight.toFixed(2)
												: watch('parcel_details.parcel_weight_kg').toFixed(2)
									  }kg`
									: ''}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container direction={'row'} justifyContent={'center'} mt={1} spacing={1}>
								<Grid
									item
									justifyContent={'center'}
									alignContent={'center'}
									alignSelf={'center'}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									mb={2}
								>
									<Alert severity="info">
										<Typography style={{ wordWrap: 'break-word' }}>
											Currently, our system supports the shipment of a single parcel per booking.
											If you need to send multiple parcels in one shipment, please  fill out this{' '}
											<Link
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO , LABEL, 'Manual form link'])}
												href="https://form.jotform.com/242121867991059"
												color="inherit"
												underline="always"
												target="_blank"
											>
												form
											</Link>
											.
										</Typography>
									</Alert>
								</Grid>
								<Grid
									item
									alignItems={'flex-start'}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									sx={{ ml: '28%', mr: '25%', mb: -8 }}
								>
									<Typography variant="h5" mb={5} mt={5}>
										Enter your parcel details
									</Typography>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={2} xl={2} sx={{ mt: '60px' }}>
									{' '}
									<TextField
										id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, TEXTFIELD, 'length'])} 
										required
										label="Length (cm)"
										type={'number'}
										onWheel={e => (e.target as HTMLElement).blur()}
										{...props.register('parcel_details.parcel_length_cm', {
											required: 'Required',
											min: {
												value: 1,
												message: 'Parcel dimensions cannot be less than 1cm'
											},
											max: {
												value: 170,
												message: 'Parcel dimensions cannot be higher than 170cm'
											},
											valueAsNumber: true
										})}
										// onChange={props.handleDimensionChange}
										error={!!props.errors?.parcel_details?.parcel_length_cm}
										helperText={
											props.errors?.parcel_details?.parcel_length_cm
												? (props.errors.parcel_details.parcel_length_cm.message as string)
												: null
										}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={2} xl={2} sx={{ mt: '60px' }}>
									{' '}
									<TextField
									id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, TEXTFIELD, 'width'])}
										required
										label="Width (cm)"
										type={'number'}
										onWheel={e => (e.target as HTMLElement).blur()}
										{...props.register('parcel_details.parcel_width_cm', {
											required: 'Required',
											min: {
												value: 1,
												message: 'Parcel dimensions cannot be less than 1cm'
											},
											max: {
												value: 100,
												message: 'Parcel dimensions cannot be higher than 100cm'
											},
											valueAsNumber: true
										})}
										// onChange={props.handleDimensionChange}
										error={!!props.errors?.parcel_details?.parcel_width_cm}
										helperText={
											props.errors?.parcel_details?.parcel_width_cm
												? (props.errors.parcel_details.parcel_width_cm.message as string)
												: null
										}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={2} xl={2} sx={{ mt: '60px' }}>
									{' '}
									<TextField
									id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, TEXTFIELD, 'height'])}
										required
										type={'number'}
										onWheel={e => (e.target as HTMLElement).blur()}
										label="Height (cm)"
										{...props.register('parcel_details.parcel_height_cm', {
											required: 'Required',
											min: {
												value: 1,
												message: 'Parcel dimensions cannot be less than 1cm'
											},
											max: {
												value: 100,
												message: 'Parcel dimensions cannot be higher than 100cm'
											},
											valueAsNumber: true
										})}
										// onChange={props.handleDimensionChange}
										error={!!props.errors?.parcel_details?.parcel_height_cm}
										helperText={
											props.errors?.parcel_details?.parcel_height_cm
												? (props.errors.parcel_details.parcel_height_cm.message as string)
												: null
										}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={2} xl={2} sx={{ mt: '60px' }}>
									{' '}
									<TextField
									id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, TEXTFIELD, 'weight'])}
										required
										type={'number'}
										onWheel={e => (e.target as HTMLElement).blur()}
										color={checkWeight ? 'warning' : 'success'}
										label={'Weight(kg)'}
										{...props.register('parcel_details.parcel_weight_kg', {
											required: 'Required',
											min: { value: 0.1, message: 'Parcel cannot be lighter than 0.1kg' },
											max: {
												value: 70,
												message:
													'For parcel weight greater than 70kg, please contact support@tunl.to'
											},
											valueAsNumber: true
										})}
										// onChange={props.handleDimensionChange}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid
									item
									xs={10}
									sm={10}
									md={5}
									lg={2}
									xl={2}
									sx={{
										mt: !isXsSmMd ? '60px' : null,
										mb: !isXsSmMd ? '60px' : null,
										ml: !isXsSmMd ? '30%' : null,
										mr: !isXsSmMd ? '5%' : null
									}}
								>
									<Tooltip
										title={
											<span>
												Learn more on how to tape down your flyer bag and save money to avoid
												being rebilled.{' '}
												<LearnMore
													href="https://help.tunl.to/en/article/measuring-your-parcel-xz7zx3/#3-an-example-of-how-not-taping-your-parcel-can-affect-the-price-you-pay"
													inline={true}
													style={{ color: 'white' }}
												/>
											</span>
										}
									>
										<img
											src="https://www.parcelhero.com/Areas/root/content/images/australia/box_size_calculation.svg"
											alt="Box Size Calculation"
											width={'165px'}
											height={'165px'}
										/>
									</Tooltip>
								</Grid>
								<Grid item xs={12} sm={12} md={10} lg={10} xl={10} sx={{ mt: '-90px', mb: '60px' }}>
									<FormControlLabel
										id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, FORM_CONTROL_LABEL, 'flyer_bag'])}
										control={
											<Checkbox
												id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, FORM_CONTROL_LABEL,CHECKBOX, 'flyer_bag'])}
												checked={isOpen}
												onClick={(e: any) => {
													handleCheckBoxChange(e);
												}}
											/>
										}
										label="Packed in a flyer bag, mailer bag or plastic pouch?"
									/>
								</Grid>
							</Grid>
							<Grid container direction={'row'} alignItems="center" justifyContent={'center'}>
								<Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
									{' '}
								</Grid>
								<Grid
									item
									xs={10}
									sm={10}
									md={5}
									lg={5}
									xl={5}
									sx={{ mt: !isXsSmMd ? '10%' : null, mb: '5%' }}
								>
									<Tooltip
										title={
											<span>
												*Length X Width X Height / 5000. For international airfreight, rates are
												based on the higher of actual or volumetric weight.{' '}
												<LearnMore
													href="https://help.tunl.to/en/article/rate-calculation-volume-vs-actual-weight-xtw5i1/"
													inline={true}
													style={{ color: 'white' }}
												/>
											</span>
										}
									>
										<Box>
											<Paper
												sx={{
													backgroundColor: 'whitesmoke',
													width: '250px',
													height: '80px'
												}}
											>
												<Grid container sx={{ mt: -4 }}>
													<Grid item>
														<Typography
															id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, KEY, 'volumetric_weight'])}
															sx={{
																fontSize: '2ch',
																ml: 6,
																mt: 1.5,
																color: volumetric_weight < 70 ? 'black' : 'black'
															}}
														>
															Volumetric Weight (Kg)
														</Typography>
														<Typography
														id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, VALUE, 'volumetric_weight'])}
															sx={{
																width: '30px',
																height: '100px',
																ml: 13,
																mr: 15,
																mt: 0,
																mb: -6.5,
																fontSize: '3ch',
																color: volumetric_weight < 70 ? 'black' : 'crimson'
															}}
														>
															{volumetric_weight.toFixed(2)}
															{/* {Math.ceil(volumetric_weight * 10) / 10} */}
														</Typography>
													</Grid>
												</Grid>
											</Paper>
										</Box>
									</Tooltip>
								</Grid>
								<Grid
									item
									xs={7}
									sm={7}
									md={5}
									lg={5}
									xl={5}
									sx={{ mt: !isXsSmMd ? '10%' : null, mb: '5%' }}
								>
									{checkWeight ? (
										<Tooltip
											title={<span>*You can save money by packing this shipment smaller. </span>}
										>
											<Box>
												<Paper
													sx={{
														backgroundColor:
															volumetric_weight < 70 &&
															props.watch('parcel_details.parcel_weight_kg') < 70
																? 'mintcream'
																: props.watch('parcel_details.parcel_weight_kg') ===
																		70 && volumetric_weight < 70
																? 'mintcream'
																: 'seashell',
														width: '250px',
														height: '80px'
													}}
												>
													<Grid container sx={{ mt: -4 }}>
														<Grid item>
															<Typography
															id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, KEY, 'chargeable_weight'])}
																sx={{
																	fontSize: '2ch',
																	fontWeight: 'bold',
																	ml: 6,
																	mt: 1.5,
																	color:
																		volumetric_weight < 70 &&
																		props.watch('parcel_details.parcel_weight_kg') <
																			70
																			? '#0e8212'
																			: props.watch(
																					'parcel_details.parcel_weight_kg'
																			  ) === 70 && volumetric_weight < 70
																			? 'orangered'
																			: 'crimson'
																}}
															>
																Chargeable Weight (Kg)
															</Typography>
															<Typography
															id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, VALUE, 'chargeable_weight'])}
																sx={{
																	width: '30px',
																	height: '100px',
																	ml: 13,
																	mr: 15,
																	mt: 0,
																	mb: -6.5,
																	fontSize: '3ch',
																	color:
																		volumetric_weight < 70 &&
																		props.watch('parcel_details.parcel_weight_kg') <
																			70
																			? '#0e8212'
																			: props.watch(
																					'parcel_details.parcel_weight_kg'
																			  ) === 70 && volumetric_weight < 70
																			? 'orangered'
																			: 'crimson',
																	fontWeight: 'bold'
																}}
															>
																{isNaN(
																	parseFloat(
																		checkWeight
																			? volumetric_weight.toFixed(2).toString()
																			: String(
																					props?.watch(
																						'parcel_details.parcel_weight_kg'
																					)
																			  )
																	)
																)
																	? '0'
																	: checkWeight
																	? volumetric_weight.toFixed(2).toString()
																	: String(
																			props?.watch(
																				'parcel_details.parcel_weight_kg'
																			).toFixed(2)
																	  )}
															</Typography>
															<Typography
																variant="subtitle2"
																sx={{ color: 'red', fortSize: '1px' }}
																style={{ wordWrap: 'break-word' }}
															>
																{volError}
															</Typography>
														</Grid>
													</Grid>
												</Paper>
											</Box>
										</Tooltip>
									) : (
										<Box>
											<Paper
												sx={{
													backgroundColor:
														volumetric_weight < 70 &&
														props.watch('parcel_details.parcel_weight_kg') < 70
															? 'mintcream'
															: props.watch('parcel_details.parcel_weight_kg') === 70 &&
															  volumetric_weight < 70
															? 'mintcream'
															: 'seashell',
													width: '250px',
													height: '80px'
												}}
											>
												<Grid container sx={{ mt: -4 }}>
													<Grid item>
														<Typography
														id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, KEY, 'chargeable_weight'])}
															sx={{
																fontSize: '2ch',
																fontWeight: 'bold',
																ml: 6,
																mt: 1.5,
																color:
																	volumetric_weight < 70 &&
																	props.watch('parcel_details.parcel_weight_kg') < 70
																		? '#0e8212'
																		: props.watch(
																				'parcel_details.parcel_weight_kg'
																		  ) === 70 && volumetric_weight < 70
																		? 'orangered'
																		: 'crimson'
															}}
														>
															Chargeable Weight (Kg)
														</Typography>
														<Typography
														id={createElementId([CREATE_SHIPMENTS, SHIPMENT_DETAILS, ACCORDION,PARCEL_INFO, LABEL, VALUE, 'chargeable_weight'])}
															sx={{
																width: '30px',
																height: '100px',
																ml: 14,
																mr: 15,
																mt: 0,
																mb: -6.5,
																fontSize: '3ch',
																color:
																	volumetric_weight < 70 &&
																	props.watch('parcel_details.parcel_weight_kg') < 70
																		? '#0e8212'
																		: props.watch(
																				'parcel_details.parcel_weight_kg'
																		  ) === 70 && volumetric_weight < 70
																		? 'orangered'
																		: 'crimson',
																fontWeight: 'bold'
															}}
														>
															{isNaN(
																parseFloat(
																	checkWeight
																		? volumetric_weight.toFixed(2).toString()
																		: String(
																				props?.watch(
																					'parcel_details.parcel_weight_kg'
																				).toFixed(2)
																		  )
																)
															)
																? '0'
																: checkWeight
																? volumetric_weight.toString()
																: String(
																			props?.watch(
																				'parcel_details.parcel_weight_kg'
																			).toFixed(2)
																		
																  )}
														</Typography>
														<Typography
															variant="subtitle2"
															sx={{ color: 'red', fortSize: '1px' }}
															style={{ wordWrap: 'break-word' }}
														>
															{volError}
														</Typography>
													</Grid>
												</Grid>
											</Paper>
										</Box>
									)}
								</Grid>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
									{' '}
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Dialog open={openModal} onClose={() => SetCloseModal(false)}>
						<FlyerBagModal
							isOpen={isOpen}
							SetCheckbox={SetCheckbox}
							openModal={openModal}
							SetCloseModal={SetCloseModal}
						/>
					</Dialog>
				</Grid>
			</Grid>
        </>)
    );
}
