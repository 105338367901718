import { CopyAll, Download, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	Divider,
	Grid,
	IconButton,
	Link,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	styled
} from '@mui/material';
import { usePDF } from '@react-pdf/renderer';
import React, { Fragment, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { axiosConfig } from '../../../constants/axios';
import { IMAGE, SERVICE, SHIPMENT_TABLE, createElementId } from '../../../constants/id';
import TEXT from '../../../constants/text';
import { useMerchant } from '../../../context/MerchantContext';
import ShipmentSummaryModal from '../../../pages/Merchant/ShipmentSummaryModal';
import { FulfillmentStatus } from '../../../types/fulfillment';
import { Shipment } from '../../../types/shipment';
import { TrackingStatus } from '../../../types/tracker';
import { SnackAlert } from '../../../types/util';
import CostItem from '../../Common/CostItem';
import EventStatusDropwdown from '../../Common/EvenStatusDropdown';
import PickupModal from '../../Shared/PickupModal';
import GenerateCommercialInvoice from './GenerateCommercialInvoice';

function Row(props: { shipment: Shipment; integration: boolean; landedCosts: any; axiosInstance: any }) {
	const { shipment, landedCosts, axiosInstance } = props;
	const merchant = JSON.parse(sessionStorage.getItem('merchant') || '{}');
	const [open, setOpen] = useState(false);
	const shipmentProtection = shipment.insurance ? parseFloat(shipment.insurance.toString()) : 0.0;
	const [pickupModalOpen, setPickupModalOpen] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [openShipSummary, setOpenShipSummary] = useState(false);
	const [deliveryConfirmationCost, setDeliveryConfirmationCost] = useState(
		shipment.delivery_confirmation === 'ADULT_SIGNATURE' ? 50.0 : 0
	);
	const adminUser = JSON.parse(sessionStorage.getItem('user') || '{}');
	const [eventStatus, setEventStatus] = useState(shipment.event_status);
	const statusText = !eventStatus
		? 'Incomplete'
		: eventStatus === TrackingStatus.Delivered
		? 'Delivered'
		: eventStatus === TrackingStatus.Cancelled
		? 'Cancelled'
		: eventStatus === TrackingStatus.PendingCancel
		? 'Pending Cancel'
		: eventStatus === TrackingStatus.Failure
		? 'Exception'
		: eventStatus === TrackingStatus.ReturnToSender
		? 'Returned'
		: eventStatus === TrackingStatus.InTransit
		? 'In Transit'
		: eventStatus === TrackingStatus.OutForDelivery
		? 'Out For Delivery'
		: !Object.values(TrackingStatus).includes(shipment.event_status as TrackingStatus) ||
		  eventStatus === TrackingStatus.PreTransit
		? 'Pre Transit'
		: eventStatus === TrackingStatus.AvailableForPickUp
		? 'Available For Pickup'
		: eventStatus;
	const [allowedStatuses, setAllowedStatuses] = useState(true);
	const isEditible =
		eventStatus === TrackingStatus.PendingCancel ||
		eventStatus === TrackingStatus.InTransit ||
		eventStatus === TrackingStatus.OutForDelivery;
	const [loading, setLoading] = useState(false);
	const statuses = [
		{
			event_status: TrackingStatus.PendingCancel,
			allowed_statuses: allowedStatuses,
			status_text: 'Pending Cancel'
		},
		{
			event_status: TrackingStatus.PreTransit,
			allowed_statuses: !allowedStatuses,
			status_text: 'Pre Transit'
		},
		{
			event_status: TrackingStatus.InTransit,
			allowed_statuses: allowedStatuses,
			status_text: 'In Transit'
		},
		{
			event_status: TrackingStatus.OutForDelivery,
			allowed_statuses: allowedStatuses,
			status_text: 'Out For Delivery'
		},
		{
			event_status: TrackingStatus.Cancelled,
			allowed_statuses: allowedStatuses,
			status_text: 'Cancelled'
		},
		{
			event_status: TrackingStatus.Delivered,
			allowed_statuses: allowedStatuses,
			status_text: 'Delivered'
		},
		{
			event_status: TrackingStatus.ReturnToSender,
			allowed_statuses: !allowedStatuses,
			status_text: 'Returned'
		},
		{
			event_status: TrackingStatus.AvailableForPickUp,
			allowed_statuses: !allowedStatuses,
			status_text: 'Available for Pickup'
		},
		{
			event_status: TrackingStatus.Failure,
			allowed_statuses: !allowedStatuses,
			status_text: 'Exception'
		}
	];

	const [instance, updateInstance] = usePDF({
		document: <GenerateCommercialInvoice shipment={shipment} merchant={merchant} />
	});

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};
	const [downloaded, setDownloaded] = useState(false);

	const disabledMessage = () => {
		return shipment.record_type !== 'Express Shipment'
			? 'Economy is a drop-off only service'
			: 'Pickups can only be changed for shipments pre-transit';
	};

	const disablePickupButton = () => {
		return (
			shipment.record_type !== 'Express Shipment' ||
			(shipment.event_status !== TrackingStatus.PreTransit && shipment.event_status !== 'TBD')
		);
	};

	const handleDownload = () => {
		window.open(shipment?.source_api_label_url_pdf, '_blank');
		setDownloaded(true);
	};

	const generateInvoice = () => {
		if (instance.url) {
			window.open(instance.url, '_blank');
			setDownloaded(true);
		}
	};

	const handleCommercialDownload = () => {
		window.open(shipment?.order_comm_invoice as string, '_blank');
		setDownloaded(true);
	};

	const Img = styled('img')({
		display: 'flex',
		width: '66px',
		height: '55px',
		maxWidth: '66px',
		maxHeight: '55px',
		padding: '2px',
		marginLeft: '15%',
		marginRight: '20%'
	});

	const handleMarkAsDelivered = async (event: any) => {
		const deliveryStatus = event.target.value;

		if (deliveryStatus === TrackingStatus.Delivered) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.OutForDelivery) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.Cancelled) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.PendingCancel) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.InTransit) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		}
		const response = await axiosInstance.post('/shipment/updateShipmentEventStatus', {
			shipment_id: shipment.shipment_id,
			event_status: deliveryStatus
		});
		if (response.status === 200) {
			setSnackAlert({ type: 'success', message: 'Shipment status updated successfully' });
			setOpenSnackBar(true);
			setLoading(false);
		} else {
			setSnackAlert({ type: 'error', message: 'Failed to update shipment status' });
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	return (<>
        <Fragment>
            <TableRow>
                <TableCell align="left">
                    <Typography noWrap={true}>
                        {new Date(shipment.date_created).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                    </Typography>
                </TableCell>
                <TableCell
                    align="left"
                    size="medium"
                    sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '190px'
                    }}
                >
                    <Typography noWrap={true}>{shipment.address_to_name}</Typography>
                </TableCell>

                <TableCell
                    align="left"
                    sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '120px'
                    }}
                >
                    <Typography noWrap={true}>{shipment.shipment_reference}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Link href={shipment.tracker_url} rel="noopener" target="_blank">
                        <Img
                            id={createElementId([
                                SHIPMENT_TABLE,
                                SERVICE,
                                shipment.source_api_carrier,
                                IMAGE,
                                'service_logo'
                            ])}
                            src={`../img/${
                                shipment.source_api_carrier === 'UPSDAP' ? 'TUNL' : shipment.source_api_carrier
                            }.${shipment.source_api_carrier === 'UPSDAP' ? 'png' : 'jpg'}`}
                        />
                    </Link>
                </TableCell>
                <TableCell align="center">
                    <Link href={shipment.tracker_url} rel="noopener" target="_blank">
                        {shipment.carrier_track_code}
                    </Link>
                </TableCell>
                <TableCell
                    align="left"
                    sx={{
                        width: '12%'
                    }}
                >
                    <EventStatusDropwdown
                        shipment={shipment}
                        TrackingStatus={TrackingStatus}
                        axiosInstance={axiosInstance}
                        setSnackAlert={setSnackAlert}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                </TableCell>
                {props.integration && (
                    <TableCell
                        align="center"
                        sx={{
                            width: '12%'
                        }}
                    >
                        <Typography
                            noWrap={true}
                            p={0.2}
                            sx={{
                                backgroundColor: !shipment.fulfillment_status ? '#fff200' : 'grey',
                                borderRadius: '20px',
                                textAlign: 'center',
                                color: !shipment.fulfillment_status ? '#000' : '#fff'
                            }}
                        >
                            {'fulfillment_status' in shipment && !shipment.fulfillment_status
                                ? 'Unfulfilled'
                                : 'fulfillment_status' in shipment && shipment.fulfillment_status
                                ? 'Fulfilled'
                                : 'Unknown'}
                        </Typography>
                    </TableCell>
                )}
                <TableCell align="center" sx={{ maxWidth: '5px' }}>
                    <>
                        {' '}
                        <Button
                            sx={{
                                backgroundColor: '#2f308d',
                                ':hover': {
                                    backgroundColor: '#171878'
                                },
                                whiteSpace: 'nowrap'
                            }}
                            variant="contained"
                            size="small"
                            onClick={() => setOpenShipSummary(true)}
                        >
                            <Link onClick={() => setOpenShipSummary(true)} rel="noopener" target="_blank"></Link>
                            View
                        </Button>
                    </>
                </TableCell>
                <TableCell sx={{ width: '5px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#E0E0E0' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container direction="row" alignContent="center" justifyContent="space-between">
                            <Grid item>
                                <Grid container direction="column" justifyContent="flex-start" maxWidth={'160px'}>
                                    <Grid item alignSelf="left">
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            To:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">{shipment.address_to_name}</Typography>
                                    </Grid>
                                    <Grid item alignSelf="left">
                                        <Typography variant="body1">{shipment.address_to_street_1},</Typography>
                                    </Grid>
                                    {shipment.address_to_street_2 !== '' && (
                                        <Grid item alignSelf="left">
                                            <Typography variant="body1">{shipment.address_to_street_2},</Typography>
                                        </Grid>
                                    )}
                                    <Grid item alignSelf="left">
                                        <Typography variant="body1" paragraph={true}>
                                            {shipment.address_to_city_locality},{' '}
                                            {shipment.address_to_state_province}, {shipment.address_to_zip_postal},{' '}
                                            {shipment.address_to_country}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Parcel:
                                    </Typography>
                                    <Typography variant="body1">
                                        {shipment.parcel_length_cm}x{shipment.parcel_height_cm}x
                                        {shipment.parcel_width_cm} cm
                                    </Typography>
                                    <Typography variant="body1">
                                        Actual Weight: {shipment.parcel_weight_kg} kg
                                    </Typography>
                                    <Typography variant="body1">
                                        Volumetric Weight: {shipment.parcel_weight_vol_kg} kg
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justifyContent="flex-start">
                                    <Grid item alignSelf="left">
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            Costs:
                                        </Typography>
                                    </Grid>

                                    <CostItem costPrice={shipment.rate_charged} costLabel={TEXT.SHIPPING_COST} />

                                    <CostItem costPrice={shipmentProtection} costLabel={TEXT.SHIPPING_PROTECTION} />

                                    <CostItem costPrice={landedCosts.landedCosts} costLabel={TEXT.DFT} />

                                    <CostItem
                                        costPrice={deliveryConfirmationCost}
                                        costLabel={TEXT.SIGNATURE_REQUIRED}
                                    />

                                    <Grid item>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            Total: {'R'}
                                            {(
                                                parseFloat(shipmentProtection.toFixed(2)) +
                                                parseFloat(shipment.rate_charged) +
                                                landedCosts.landedCosts +
                                                deliveryConfirmationCost
                                            ).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Customer Details:
                                    </Typography>
                                    <Link
                                        href={'mailto:' + shipment.address_to_email}
                                        color="inherit"
                                        underline="always"
                                    >
                                        <Typography variant="body1">{shipment.address_to_email}</Typography>
                                    </Link>
                                    <Typography variant="body1">{shipment.address_to_phone}</Typography>
                                </Grid>
                                {shipment.xero_invoice_number && (
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                TUNL Invoice Number:
                                            </Typography>
                                            <Typography variant="body1">{shipment.xero_invoice_number}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item direction={'column'}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Tracking Code:
                                </Typography>
                                <Typography variant="body1" noWrap={true}>
                                    <CopyToClipboard text={shipment.carrier_track_code}>
                                        <IconButton color="primary" component="span" size="large">
                                            <Typography>Copy to Clipboard</Typography>
                                            <CopyAll />
                                        </IconButton>
                                    </CopyToClipboard>
                                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            sx={{
                                                borderRadius: '30px',
                                                height: '30px'
                                            }}
                                            fullWidth
                                            onClick={handleDownload}
                                        >
                                            <Download />
                                            <Typography>Label</Typography>
                                        </Button>
                                    </Grid>
                                    {shipment.record_type === 'Express Shipment' && (
                                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} mt={0.5}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={!shipment.order_comm_invoice}
                                                sx={{
                                                    borderRadius: '30px',
                                                    height: '30px'
                                                }}
                                                fullWidth
                                                onClick={handleCommercialDownload}
                                            >
                                                <Download />
                                                <Typography>Invoice</Typography>
                                            </Button>
                                        </Grid>
                                    )}
                                    {shipment.record_type !== 'Express Shipment' && (
                                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} mt={0.5}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                sx={{
                                                    borderRadius: '30px',
                                                    height: '30px'
                                                }}
                                                fullWidth
                                                onClick={generateInvoice}
                                            >
                                                <Download />
                                                Invoice
                                            </Button>
                                        </Grid>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
        {
            <>
                <Dialog
                    open={pickupModalOpen}
                    onClose={() => setPickupModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <PickupModal
                        shipment={shipment}
                        setPickupModalOpen={setPickupModalOpen}
                        setSnackAlert={setSnackAlert}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                </Dialog>
                <Dialog
                    open={openShipSummary}
                    onClose={() => setOpenShipSummary(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ShipmentSummaryModal
                        shipment={shipment}
                        openShipSummary={openShipSummary}
                        setOpenShipSummary={setOpenShipSummary}
                        landedCosts={landedCosts}
                    />
                </Dialog>
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={2000}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
                        {snackAlert.message}
                    </Alert>
                </Snackbar>
            </>
        }
    </>);
}

export default function ShipmentsTable(props: any) {
	const { merchantProfile } = useMerchant();
	const [page, setPage] = useState(0);
	const [shipments, setShipments] = useState<Shipment[]>([]);
	const [loading, setLoading] = useState(false);
	const [filterField, setFilterField] = useState('All');
	const [filterValue, setFilterValue] = useState('All');
	const [searchField, setSearchField] = useState('All');
	const [searchValue, setSearchValue] = useState('');
	const [pageCount, setPageCount] = useState(props.shipmentsCount);
	const [openFilterAccordion, setOpenFilterAccordion] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const integration = merchantProfile?.integrations && merchantProfile.integrations.length > 0 ? true : false;
	const [getExtractedData, setExtractedData] = useState<{ [key: number]: any }>({});
	const axiosInstance = axiosConfig();

	const extractData = async (value: any) => {
		const data = value.data?.data || [];
		const extractedData = data.reduce((acc: any, costs: any, index: number) => {
			const landedCosts = costs.rates_breakdown?.ddp?.landed_costs_total_rands || 0;

			acc[index] = { landed_costs: { landedCosts } };
			setExtractedData(acc);
			return acc;
		}, {});

		return extractedData;
	};

	const pageChangeGetShipmentsCall = async (newPage: any) => {
		let response;
		setLoading(true);
		try {
			setShipments([]);
			if (filterValue !== 'All' && filterField !== 'All' && searchValue && searchField !== 'All') {
				response = await axiosInstance.post('/shipment/searchFilteredShipments', {
					merchantId: props.merchantId,
					searchField: searchField,
					searchValue: searchValue,
					filterField: filterField,
					filterValue: filterValue,
					pageSize: rowsPerPage,
					pageNumber: newPage
				});
			} else if (searchField !== 'All' && searchValue) {
				response = await axiosInstance.post('/shipment/searchShipments', {
					merchantId: props.merchantId,
					searchField: searchField,
					searchValue: searchValue,
					pageSize: rowsPerPage,
					pageNumber: page
				});
			} else if (filterValue !== 'All') {
				response = await axiosInstance.post('/shipment/getFilteredShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: newPage,
					filterField: filterField,
					filterValue: filterValue
				});
			} else {
				response = await axiosInstance.post('/shipment/getBatchedShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: newPage
				});
			}
			setPageCount(response.data.count);
			setShipments(response.data.data);
			setPage(newPage);
			await extractData(response);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const handleChangePage = async (event: any, newPage: any) => {
		const previousPage = newPage < page;
		pageChangeGetShipmentsCall(newPage);
	};

	const handleFilterAccordionChange = () => {
		setOpenFilterAccordion(!openFilterAccordion);
	};

	useEffect(() => {
		pageChangeGetShipmentsCall(page);
	}, [rowsPerPage]);

	const handleRowsPerPageChange = (event: any) => {
		setRowsPerPage(event.target.value);
	};

	const handleFilterValueChange = async (event: any) => {
		setFilterValue(event.target.value);
		setLoading(true);

		let response;

		try {
			setShipments([]);
			if (event.target.value !== 'All' && filterField !== 'All' && searchValue && searchField !== 'All') {
				response = await axiosInstance.post('/shipment/searchFilteredShipments', {
					merchantId: props.merchantId,
					searchField: searchField,
					searchValue: searchValue,
					filterField: filterField,
					filterValue: event.target.value,
					pageSize: rowsPerPage,
					pageNumber: page
				});
			} else if (searchField !== 'All' && searchValue) {
				response = await axiosInstance.post('/shipment/searchShipments', {
					merchantId: props.merchantId,
					searchField: searchField,
					searchValue: searchValue,
					pageSize: rowsPerPage,
					pageNumber: page
				});
			} else if (event.target.value === 'All') {
				response = await axiosInstance.post('/shipment/getBatchedShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: page
				});
			} else {
				response = await axiosInstance.post('/shipment/getFilteredShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: page,
					filterField: filterField,
					filterValue: event.target.value
				});
			}
			setPageCount(response.data.count);
			setShipments(response.data.data);
			await extractData(response);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const handleFilterFieldChange = (event: any) => {
		if (event.target.value === 'All') {
			setFilterValue('All');
			setShipments([]);
			setLoading(true);
			axiosInstance
				.post('/shipment/getBatchedShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: page
				})
				.then(response => {
					setPageCount(response.data.count);
					setShipments(response.data.data);
					extractData(response);
					setLoading(false);
				})
				.catch(error => {
					console.log(error);
					setLoading(false);
				});
		}
		setFilterField(event.target.value);
	};

	const handleSearchFieldChange = (event: any) => {
		setSearchField(event.target.value);
	};

	const handleSearchValueChange = (event: any) => {
		if (event.target.value === '' && filterValue !== 'All') {
			axiosInstance
				.post('/shipment/getFilteredShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: page,
					filterField: filterField,
					filterValue: filterValue
				})
				.then(response => {
					setPageCount(response.data.count);
					setShipments(response.data.data);
					extractData(response);
					setLoading(false);
				})
				.catch(error => {
					console.log(error);
					setLoading(false);
				});
		} else if (event.target.value === '' && filterValue === 'All') {
			axiosInstance
				.post('/shipment/getBatchedShipmentsByMerchant', {
					merchantId: props.merchantId,
					pageSize: rowsPerPage,
					pageNumber: page
				})
				.then(response => {
					setPageCount(response.data.count);
					setShipments(response.data.data);
					extractData(response);
					setLoading(false);
				})
				.catch(error => {
					console.log(error);
					setLoading(false);
				});
		}
		setSearchValue(event.target.value);
	};

	useEffect(() => {
		setShipments(props.shipments);
		setPageCount(props.shipments.length);
	}, [props.shipments]);

	return (<>
        <Accordion onChange={handleFilterAccordionChange}>
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                <SearchIcon />
                <Typography>Search shipments</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {' '}
                        <Grid container direction={'row'} spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Select value={filterField} onChange={handleFilterFieldChange} fullWidth>
                                    <MenuItem value={'All'}>Filter By</MenuItem>
                                    <MenuItem value={'event_status'}>Tracking Status</MenuItem>
                                    {integration && (
                                        <MenuItem value={'fulfillment_status'}>Fulfilment Status</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            {filterField === 'event_status' || filterField === 'All' ? (
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <Select
                                        value={filterValue}
                                        onChange={handleFilterValueChange}
                                        disabled={filterField === 'All'}
                                        fullWidth
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={TrackingStatus.AvailableForPickUp}>
                                            Available For Pickup
                                        </MenuItem>
                                        <MenuItem value={TrackingStatus.Cancelled}>Cancelled</MenuItem>
                                        <MenuItem value={TrackingStatus.Delivered}>Delivered</MenuItem>
                                        <MenuItem value={TrackingStatus.Failure}>Failure</MenuItem>
                                        <MenuItem value={TrackingStatus.InTransit}>In Transit</MenuItem>
                                        <MenuItem value={TrackingStatus.OutForDelivery}>Out For Delivery</MenuItem>
                                        <MenuItem value={TrackingStatus.PendingCancel}>Pending Cancel</MenuItem>
                                        <MenuItem value={TrackingStatus.PreTransit}>Pre Transit</MenuItem>
                                        <MenuItem value={TrackingStatus.ReturnToSender}>Returned</MenuItem>
                                    </Select>
                                </Grid>
                            ) : (
                                integration && (
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <Select value={filterValue} onChange={handleFilterValueChange} fullWidth>
                                            <MenuItem value={'All'}>All</MenuItem>
                                            <MenuItem value={FulfillmentStatus.Fulfilled}>Fulfilled</MenuItem>
                                            <MenuItem value={FulfillmentStatus.Unfulfilled}>Unfulfilled</MenuItem>
                                            <MenuItem value={FulfillmentStatus.Unknown}>Unknown</MenuItem>
                                        </Select>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container direction={'row'} spacing={1}>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Select value={searchField} onChange={handleSearchFieldChange} fullWidth>
                                    <MenuItem value={'All'}>Search On Field</MenuItem>
                                    <MenuItem value={'shipment_reference'}>Reference</MenuItem>
                                    <MenuItem value={'carrier_track_code'}>Tracking Code</MenuItem>
                                    <MenuItem value={'address_to_name'}>Recipient Name</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <TextField
                                    label="Search"
                                    value={searchValue}
                                    disabled={searchField === 'All'}
                                    fullWidth
                                    onChange={handleSearchValueChange}
                                    error={!searchValue.length && searchValue.length < 3 && searchField !== 'All'}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <Tooltip title={'Please Select A Field To Search On'}>
                                                    <span>
                                                        <IconButton
                                                            disabled={
                                                                searchValue.length < 1 || searchField === 'All'
                                                            }
                                                            onClick={() => {
                                                                setLoading(true);
                                                                setShipments([]);
                                                                if (
                                                                    filterValue !== 'All' &&
                                                                    filterField !== 'All' &&
                                                                    searchValue &&
                                                                    searchField !== 'All'
                                                                ) {
                                                                    axiosInstance
                                                                        .post('/shipment/searchFilteredShipments', {
                                                                            merchantId: props.merchantId,
                                                                            searchField: searchField,
                                                                            searchValue: searchValue,
                                                                            filterField: filterField,
                                                                            filterValue: filterValue,
                                                                            pageSize: rowsPerPage,
                                                                            pageNumber: page
                                                                        })
                                                                        .then(response => {
                                                                            setPageCount(response.data.count || 0);
                                                                            setShipments(response.data.data);
                                                                            extractData(response);
                                                                            setLoading(false);
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error);
                                                                            setLoading(false);
                                                                        });
                                                                } else {
                                                                    axiosInstance
                                                                        .post('/shipment/searchShipments', {
                                                                            merchantId: props.merchantId,
                                                                            searchField: searchField,
                                                                            searchValue: searchValue,
                                                                            pageSize: rowsPerPage,
                                                                            pageNumber: page
                                                                        })
                                                                        .then(response => {
                                                                            setPageCount(response.data.count || 0);
                                                                            setShipments(response.data.data);
                                                                            extractData(response);
                                                                            setLoading(false);
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error);
                                                                            setLoading(false);
                                                                        });
                                                                }
                                                            }}
                                                            size="large">
                                                            <Search />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        <Divider />
        <TableContainer component={Paper} sx={{ backgroundColor: '#F5F5F5' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            Date
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            To
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            Reference
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Carrier
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Track
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Tracking Status
                        </TableCell>
                        {integration && (
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                Fulfillment Status
                            </TableCell>
                        )}
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Details
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>More</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shipments.map((shipmentRow: Shipment, index: number) => {
                        const landedCosts = getExtractedData[index]?.landed_costs || {
                            landedCosts: 0
                        };
                        return (
                            <Row
                                key={shipmentRow.shipment_id}
                                shipment={shipmentRow}
                                integration={integration}
                                landedCosts={landedCosts}
                                axiosInstance={axiosInstance}
                            />
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={4}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="td"
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>);
}
