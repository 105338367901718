import { Alert, Box, Grid, Snackbar, Typography } from '@mui/material';
import Papa from 'papaparse';
import { useRef, useState } from 'react';
import { axiosConfig } from '../../../constants/axios';
import { MerchantRecord } from '../../../types/merchant';
import {
	LandedCostRequest,
	MockShipment,
	ShippingRateLandedCostRequest,
	ShippingRateRequest
} from '../../../types/mockShipments';
import { SnackAlert } from '../../../types/util';
import { YupValidationError } from '../../../types/yupError';
import { parseCSVToMockShipmentData } from '../../../util/serializers/csvToMockShipments';
import { parseMockShipmenToCSV } from '../../../util/serializers/mockShipmentsToCSV';
import BulkBuyLabel from './BulkBuyLabels';
import BulkOrdersTableView from './BulkOrdersTableView';
import UploadCSV from './UploadCSV';
import { CSVHeaderValidator } from './utils/CSVHeaderValidator';
import CSVRowValidator from './utils/CSVRowValidator';

// Files types can be added on
const acceptableCSVFileTypes = '.csv';

export enum BulkShipmentPageState {
	ImportCSV,
	ViewOrders,
	ViewFailures,
	BuyShipments
}

export const calculateInsuredValue = (insuredValue: number) => {
	let insuranceCost: number;
	if (!insuredValue) {
		insuranceCost = 0;
	} else if (insuredValue <= 2000) {
		insuranceCost = 50;
	} else {
		insuranceCost = insuredValue * 0.025;
	}
	return insuranceCost.toFixed(2);
};

export const calculateTotalRate = (rowItem: any) => {
	const shippingRate = Number(rowItem?.selected_rate?.rate) || 0;
	let landedCostRate = 0;
	const landedCost = rowItem?.landed_cost;
	const insurance = calculateInsuredValue(rowItem.shipment_data?.insurance);
	const deliveryCost = rowItem.shipment_data?.signature ? 50 : 0;

	if (rowItem?.service_type === 'DAP' || rowItem.selected_rate?.service === 'Ground') {
		return (Number(shippingRate) + Number(insurance) + deliveryCost).toFixed(2);
	}

	if (landedCost && landedCost.amountSubtotals) {
		const { amountSubtotals } = landedCost;
		let landedCostTotal = amountSubtotals.duties + amountSubtotals.fees + amountSubtotals.taxes;
		const { exchangeRate } = landedCost;
		if (exchangeRate) {
			if (exchangeRate.sourceCurrencyCode !== exchangeRate.targetCurrencyCode) {
				landedCostTotal = parseFloat((landedCostTotal * exchangeRate.rate).toFixed(2));
			}
		}
		landedCostRate = Number(landedCostTotal);
	}
	return (Number(shippingRate) + Number(landedCostRate) + Number(insurance) + deliveryCost).toFixed(2);
};

export default function BulkShipment() {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const merchant: MerchantRecord = JSON.parse(sessionStorage.getItem('merchant') as string);
	const axiosInstance = axiosConfig();
	//Page State
	const [inProgress, setInProgress] = useState<boolean | null>(null);
	const [uploadComplete, setUploadComplete] = useState(false);
	const [importCSVErrors, setImportCSVErrors] = useState<boolean>(false);
	const [importCSVErrorsMsg, setImportCSVErrorsMsg] = useState<string | null>('');
	const [stepper, setStepper] = useState<BulkShipmentPageState>(BulkShipmentPageState.ImportCSV);

	//Data
	const [fileHeaders, setFileHeaders] = useState<Array<string>>([]);
	const [validatedErrors, setValidationErrors] = useState<Array<YupValidationError>>([]);
	const [mockShipmentListTemp, setMockShipmentListTemp] = useState<any | null>(null);
	const [selectedMockShipmentListTemp, setSelectedMockShipmentListTemp] = useState<Array<MockShipment> | null>(null);
	const [successfulOrderImportListTemp, setSuccessfulOrderImportListTemp] = useState<Array<MockShipment> | null>(
		null
	);
	const [failedOrderImportsListTemp, setFailedOrderImportsListTemp] = useState<string[][] | null>(null);
	//Alerts
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [hideDuration, setHideDuration] = useState<number | null>(3000);

	const getExpressRatesLandedCostNoShipment = async (
		expressRatesNoShipmentDataList: ShippingRateLandedCostRequest[],
		shipmentsListData: Array<MockShipment>,
		isValidHeaders: boolean
	) => {
		try {
			const expressRatesLandedCostRes = await axiosInstance.post(
				'/getExpressRatesLandedCostNoShipment',
				expressRatesNoShipmentDataList
			);
			const tableData = shipmentsListData.map((item, index) => {
				const rateData = {
					shipment_data: { ...item },
					...expressRatesLandedCostRes.data.data[index]
				};
				const totalCost = calculateTotalRate(rateData);
				rateData.totalCost = totalCost;
				rateData.shipment_data.rate = rateData.selected_rate?.rate;
				return rateData;
			});
			setMockShipmentListTemp(tableData);
			setImportCSVErrors(!isValidHeaders);
		} catch (error) {
			setHideDuration(null);
			setSnackAlert({
				type: 'error',
				message: 'Something went wrong. Cannot calculate shipping rates at the moment.'
			});
			setOpenSnackBar(true);
			setInProgress(null);
		}
	};

	const onSuccessfulCSVImport = async (orderListToGetRatesList: Array<MockShipment>, isValidHeaders: boolean) => {
		const expressRatesNoShipmentDataList = orderListToGetRatesList?.map(dataItem =>
			parseShipmentDataToGetExpressRatesLandedCostNoShipment(dataItem, merchant)
		);

		await getExpressRatesLandedCostNoShipment(
			expressRatesNoShipmentDataList ?? [],
			orderListToGetRatesList ?? [],
			isValidHeaders
		);
	};

	const extractRowNumber = (input: string): number | null => {
		const match = input.match(/\[(\d+)\]/);
		return match ? parseInt(match[1], 10) : null;
	};

	const extractColumnField = (input: string): string | null => {
		const match = input.match(/\.(.+)$/);
		return match ? match[1] : null;
	};

	const validateCSVData = async (shipmentsListData: Array<MockShipment>): Promise<Array<MockShipment>> => {
		const validationResult = await CSVRowValidator.validateShipmentSchema(shipmentsListData);
		if (validationResult.isValid) {
			setSuccessfulOrderImportListTemp(shipmentsListData);
			setMockShipmentListTemp(shipmentsListData);
			return shipmentsListData;
		} else {
			const indexesToRemove = validationResult.errors?.map(error => extractRowNumber(error?.path ?? ''));
			const errorObjectListToAppend: Array<YupValidationError> = (
				validationResult.errors?.map(error => {
					try {
						return {
							index: extractRowNumber(error?.path ?? ''),
							field: extractColumnField(error?.path ?? ''),
							message: error?.message || 'Unknown error'
						};
					} catch (e) {
						return null;
					}
				}) ?? []
			).filter((error): error is YupValidationError => error !== null);
			
			const indexesSet = new Set(indexesToRemove);
			const successfulOrderImportList = shipmentsListData.filter((_, index) => !indexesSet.has(index));
			const failedOrderImportList = shipmentsListData.filter((_, index) => indexesSet.has(index));
			const flattenedFailedOrderImportList: string[][] = parseMockShipmenToCSV(failedOrderImportList);
			setValidationErrors(errorObjectListToAppend);
			setSuccessfulOrderImportListTemp(successfulOrderImportList);
			setFailedOrderImportsListTemp(flattenedFailedOrderImportList);
			setMockShipmentListTemp(successfulOrderImportList);
			if (successfulOrderImportList.length === 0) {
				return [];
			}
			return successfulOrderImportList;
		}
	};

	const onFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUploadComplete(false);
		const file = event.target.files?.[0];
		Papa.parse(file!, {
			complete: async function (results: any) {
				setInProgress(true);
				/**
				 * Validates CSV Headers
				 */
				setFileHeaders(results.data[0]);
				setImportCSVErrorsMsg(CSVHeaderValidator.csvHeaders(results.data[0]));
				const isValidHeaders = importCSVErrorsMsg === '' ? true : false;
				/**
				 * Validates CSV Line Items
				 */

				if (!isValidHeaders) {
					setTimeout(() => {
						setInProgress(false);
						setImportCSVErrors(!isValidHeaders);
					}, 3000);
				} else {
					const shipmentsListData: Array<MockShipment> = parseCSVToMockShipmentData(results.data, merchant);
					const orderListToGetRatesList = await validateCSVData(shipmentsListData);
					if (orderListToGetRatesList?.length > 0) {
						await onSuccessfulCSVImport(orderListToGetRatesList, isValidHeaders);
					}
					setUploadComplete(true);
					setInProgress(false);
				}
			}
		});
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const onUploadClickHandler = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const setShipmentRateRequest = (dataItem: MockShipment, merchant: MerchantRecord): ShippingRateRequest => {
		return {
			from_address: {
				address_from_name: merchant.merchant_name,
				address_from_company: merchant.merchant_name,
				address_from_street_1: merchant.merchant_street_1,
				address_from_street_2: merchant.merchant_street_2,
				address_from_city_locality: merchant.merchant_city_locality,
				address_from_state_province: merchant.merchant_state_province,
				address_from_zip_postal: merchant.merchant_zip_postal,
				address_from_country: merchant.merchant_country,
				address_from_phone: merchant.merchant_phone,
				address_from_email: merchant.merchant_email
			},
			to_address: {
				address_to_name: dataItem.address_to_name ?? '',
				address_to_company: dataItem.address_to_company,
				address_to_street_1: dataItem.address_to_street_1 ?? '',
				address_to_street_2: dataItem.address_to_street_2 ?? '',
				address_to_city_locality: dataItem.address_to_city_locality ?? '',
				address_to_state_province: dataItem.address_to_state_province ?? '',
				address_to_zip_postal: CSVHeaderValidator.validatePostalCode(
					dataItem.address_to_country,
					dataItem.address_to_zip_postal
				),
				address_to_country: dataItem.address_to_country,
				address_to_phone: dataItem.address_to_phone,
				address_to_email: dataItem.address_to_email
			},
			parcel_details: {
				parcel_height_cm: Number(dataItem.parcel_height_cm ?? 0),
				parcel_length_cm: Number(dataItem.parcel_length_cm ?? 0),
				parcel_weight_kg: Number(dataItem.parcel_weight_kg ?? 0),
				parcel_width_cm: Number(dataItem.parcel_width_cm ?? 0)
			}
		};
	};

	const setLandedCostsRequest = (dataItem: MockShipment): LandedCostRequest => {
		const items = dataItem.customs_info.customs_items.map((item, index) => {
			return {
				id: `item ${index + 1}`,
				amount: item.value,
				country_of_origin: 'ZA',
				hs_code: item.hs_tariff_number,
				quantity: 1 //item.quantity
			};
		});

		return {
			currency: dataItem.customs_info.customs_items[0].currency,
			discounts: [
				{
					id: 'sale124',
					amount: 0.0,
					detail: null
				}
			],
			items: items,
			incoterm: dataItem.shipping_type ? 'delivery_duty_paid' : 'delivery_duty_unpaid',
			ship_from_country: 'ZA',
			ship_to: {
				city: dataItem?.address_to_city_locality ?? '',
				country: dataItem?.address_to_country ?? '',
				postal_code: dataItem?.address_to_zip_postal ?? '',
				state: dataItem?.address_to_state_province ?? ''
			},
			shipping: {
				amount: 0,
				service_level:
					dataItem.service === 'FEDEX_INTERNATIONAL_PRIORITY'
						? 'fedex_international_priority'
						: dataItem.service === 'UPS_SAVER'
						? 'ups_express_saver'
						: ''
			},
			tariff_rate: 'maximum'
		};
	};

	const parseShipmentDataToGetExpressRatesLandedCostNoShipment = (
		dataItem: MockShipment,
		merchant: MerchantRecord
	): ShippingRateLandedCostRequest => {
		const expressRatesLandedCostNoShipmentData: ShippingRateLandedCostRequest = {
			shipment: setShipmentRateRequest(dataItem, merchant),
			landed_costs: setLandedCostsRequest(dataItem),
			service: dataItem.service,
			shipping_type: dataItem.shipping_type
		};
		return expressRatesLandedCostNoShipmentData;
	};

	const headersToSnakeCase = (header: string): string => {
		return header
			.replace(/\s/g, '_') // Replace spaces with underscores
			.replace(/[()]/g, '') // Remove parentheses
			.replace(/[^\w_]/g, '') // Remove any non-word characters except underscores
			.toLowerCase(); // Convert to lowercase
	};

	function renderPageContent(stepper: BulkShipmentPageState) {
		switch (stepper) {
			case BulkShipmentPageState.ViewOrders:
				return (
					<BulkOrdersTableView
						setStepper={setStepper}
						setInProgress={setInProgress}
						mockShipmentListTemp={mockShipmentListTemp}
						setSelectedMockShipmentListTemp={setSelectedMockShipmentListTemp}
						selectedMockShipmentListTemp={selectedMockShipmentListTemp}
					/>
				);
			case BulkShipmentPageState.BuyShipments:
				return (
					<BulkBuyLabel
						selectedMockShipmentListTemp={selectedMockShipmentListTemp}
						setSelectedMockShipmentListTemp={setSelectedMockShipmentListTemp}
						setStepper={setStepper}
					/>
				);

			default:
				return (
					<UploadCSV
						fileInputRef={fileInputRef}
						onFileChangeHandler={onFileChangeHandler}
						acceptableCSVFileTypes={acceptableCSVFileTypes}
						onUploadClickHandler={onUploadClickHandler}
						inProgress={inProgress}
						setInProgress={setInProgress}
						importCSVErrors={importCSVErrors}
						setImportCSVErrors={setImportCSVErrors}
						importCSVErrorMsg={importCSVErrorsMsg ?? ''}
						setStepper={setStepper}
						successfulOrderImportList={successfulOrderImportListTemp}
						failedOrderImportList={failedOrderImportsListTemp}
						fileHeaders={fileHeaders}
						validatedErrors={validatedErrors}
					/>
				);
		}
	}

	return (
		<Box>
			<Grid container direction="column" alignItems="left">
				<Grid item md={12}>
					<Typography variant="h4" m={2}>
						Bulk Shipments
					</Typography>
				</Grid>
				<Grid item md={12}>
					{renderPageContent(stepper)}
				</Grid>
			</Grid>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={hideDuration}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}
