import React from 'react';
import './util/instrument';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import theme from './util/theme';
import { ThemeProvider as AmplifyTheme, Authenticator, createTheme as createAmplifyTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Crisp } from 'crisp-sdk-web';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

import './index.css';

import Tracker from './pages/Merchant/Tracker';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import awsExports from './aws-exports';
import TrackerLayout from './components/Layout/TrackerLayout';
import Main from './pages/Main';
import getAmplifyTheme from './util/amplifyTheme';

// declare module '@mui/styles/defaultTheme' {
// 	interface DefaultTheme extends Theme {}
// }

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	session_recording: {
		maskAllInputs: false
	},
	capture_pageview: false
});

const container = document.getElementById('root')!;
const root = createRoot(container);

const amplifyTheme = createAmplifyTheme(getAmplifyTheme(theme));

Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID!);
Amplify.configure(awsExports);

root.render(
	<>
		<Provider store={store}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline>
						<AmplifyTheme theme={amplifyTheme}>
							{window.location.pathname.split('/')[1] === 'tracker' ? (
								<TrackerLayout>
									<Routes>
										<Route path="/tracker/:trackerId" element={<Tracker />} />
										<Route path="/tracker/" element={<Tracker />} />
									</Routes>
								</TrackerLayout>
							) : (
								<Authenticator.Provider>
									<PostHogProvider client={posthog}>
										<Main />
									</PostHogProvider>
								</Authenticator.Provider>
							)}
						</AmplifyTheme>
					</CssBaseline>
				</ThemeProvider>
			</BrowserRouter>
		</Provider>
	</>
);
