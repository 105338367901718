import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	Link,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
	Snackbar,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import LearnMore from '../../components/Common/LearnMore';
import ProductsTable from '../../components/Merchant/Product/ProductsTable';
import { axiosConfig } from '../../constants/axios';
import {
	ALERT,
	BUTTON,
	createElementId,
	DROPDOWN,
	FORM_CONTROL,
	FORM_CONTROL_LABEL,
	HEADING,
	LINK,
	MENU_ITEM,
	MODAL,
	PRODUCT_LIBRARY,
	SELECT,
	TEXTFIELD,
	TOOLTIP
} from '../../constants/id';
import { ProductRecord } from '../../types/product';
import { UserMerchantRecord } from '../../types/user';
import { MerchantRecord } from '../../types/merchant';
import { SnackAlert } from '../../types/util';
import { invalidChapters, warningChapters } from './HsCodeRestrictions';
import { useMerchant } from '../../context/MerchantContext';
import TNAlerts from '../../components/Common/TNAlerts';
// import LearnMore from '../../components/Common/LearnMore/LearnMore';

interface LocationState {
	type: string;
	message: string;
}

export default function ProductLibrary(props: any) {
	const { merchantProfile } = useMerchant();
	const location = useLocation();
	const [modalOpen, setModalOpen] = useState(false);
	const [products, setProducts] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [updateOpen, setUpdateOpen] = useState(true);
	const handleLeaveClose = () => setModalOpen(false);

	const state = location.state as LocationState | undefined;
	const [itemDescription, setItemDescription] = useState('');
	const [itemMaterial, setItemMaterial] = useState('');
	const [itemGender, setItemGender] = useState('Other');
	const [classificationResult, setClassificationResult] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [customsDescription, setCustomsDescription] = useState('');
	const [showCustomsDescription, setShowCustomsDescription] = useState(false);
	const [htsCodeError, setHtsCodeError] = useState('');
	const [htsCodeWarning, setHtsCodeWarning] = useState('');
	const [getHtsCode, setHtsCode] = useState('');
	const [alertOpen, setAlertOpen] = useState(false);
	const [confidence, setConfidence] = useState(0);
	const [codeError, setCodeError] = useState(false) as any;
	const [codeWarning, setCodeWarning] = useState(false) as any;
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const axiosInstance = axiosConfig();

	const classifyItem = async () => {
		setIsLoading(true);

		try {
			const response = await axiosInstance.post('/zonos/zonosClassify', {
				item: {
					id: '',
					description: `${itemGender} ${itemMaterial} ${itemDescription}`,
					material: itemMaterial
				},
				ship_to_country: 'US'
			});

			const formattedHtsCode = response.data.data?.classified_code.replace(/\./g, '');
			const chapter = response.data.data?.codes[3].code;
			setConfidence(response.data.data?.confidence_score.toFixed(2));
			setValue('hts_code', formattedHtsCode);
			setHtsCode(formattedHtsCode);
			setCustomsDescription(response.data.data?.combined_description);
			const htsCode = checkCodeStartsWith(chapter);
			setHtsCodeError(htsCode);
			const htsCodeWarning = checkWarningCodes(chapter);
			setHtsCodeWarning(htsCodeWarning);
			setClassificationResult('');
		} catch (error) {
			console.error('API request error:', error);
			setClassificationResult(
				`Error: Unable to lookup the HS Code for this item. Please try again later.\n ${error}`
			);
		} finally {
			setIsLoading(false);
			setShowCustomsDescription(true);
		}
	};

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm({
		defaultValues: {
			description: '',
			origin_country: '',
			hts_code: ' ',
			unit_weight: ' ',
			unit_price: ' '
		}
	});

	const message = state && state.message ? state.message : '';

	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	useEffect(() => {
		htsCodeError === 'Invalid Code'
			? setCodeError(true)
			: htsCodeWarning === 'Warning Code'
			? setCodeWarning(true)
			: setCodeError(false) || setCodeWarning(false);
	}, [htsCodeWarning, htsCodeError]);

	useEffect(() => {
		setLoading(true);
		axiosInstance
			.post('/product/getProductsByMerchantId', {
				merchant_id: merchantProfile.partitionKey
			})
			.then(response => {
				setProducts(response.data);
				setLoading(false);
			})
			.catch(err => {
				setApiResponse({
					type: 'error',
					message: 'Error: failed to get products'
				});
				setOpenSnackBar(true);
				setLoading(false);
				console.log(err);
			});
	}, [merchantProfile]);

	useEffect(() => {
		if (!modalOpen) {
			setItemDescription('');
			setValue('hts_code', ' ');
			setValue('unit_weight', ' ');
			setValue('unit_price', ' ');
			setValue('origin_country', '');
			setShowCustomsDescription(false);
			setHtsCodeError('');
			setHtsCodeWarning('');
			setHtsCode('');
			setClassificationResult('');
			setCodeError(false);
			setCodeWarning(false);
		} else if (classificationResult !== '') {
			setCustomsDescription('');
			setHtsCodeError('');
			setHtsCodeWarning('');
			setHtsCode('');
			setCodeError(false);
			setCodeWarning(false);
		}
	}, [modalOpen, classificationResult]);

	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const resetModalStyleSm = {
		position: 'absolute' as 'absolute',
		top: 'auto',
		left: '10%',
		right: '10%',
		bottom: 'auto',
		transform: 'auto',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const addProduct = async (data: any) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/product/createProduct', {
				merchant_id: merchantProfile.partitionKey,
				merchant_name: merchantProfile.merchant_name,
				...data
			});

			setProducts(current => [...current, response.data]);
			setSnackAlert({ type: 'success', message: 'Product added!' });
			setOpenSnackBar(true);
			setLoading(false);

			reset({
				description: '',
				origin_country: '',
				hts_code: ' ',
				unit_weight: ' ',
				unit_price: ' '
			});
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		} finally {
			setLoading(false);
			setModalOpen(false);
		}
	};

	const updateProduct = async (updatedProduct: ProductRecord) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/product/createProduct', {
				...updatedProduct
			});

			setProducts(current => [...current, response.data]);

			const updatedProducts = products.map(product => {
				if (product._id === updatedProduct._id) {
					return updatedProduct;
				}
				return product;
			});

			setProducts(updatedProducts);
			setSnackAlert({ type: 'success', message: 'Product updated!' });
			setOpenSnackBar(true);
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
		setModalOpen(false);
	};

	const checkCodeStartsWith = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return getInvalidChapters.includes(code) ? 'Invalid Code' : '';
	};

	const checkWarningCodes = (code: string): string => {
		const getWarningChapters = warningChapters;
		return getWarningChapters.includes(code) ? 'Warning Code' : '';
	};

	const checkChapterRestriction = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return code.length > 5 && code.length < 11
			? getInvalidChapters.includes(code.slice(0, 2))
				? 'Invalid Code'
				: ''
			: 'Invalid length';
	};

	const checkChapterWarning = (code: string): string => {
		const getWarningChapters = warningChapters;
		return code.length > 5 && code.length < 11
			? getWarningChapters.includes(code.slice(0, 2))
				? 'Warning Code'
				: ''
			: 'Invalid length';
	};

	const handleHtsCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const htsCode = checkChapterRestriction(event.target.value.trim());
		setHtsCodeError(htsCode);
		setValue('hts_code', event.target.value.trim());
	};

	const handleHtsCodeWarning = (event: React.ChangeEvent<HTMLInputElement>) => {
		const htsCode = checkChapterWarning(event.target.value.trim());
		setHtsCodeWarning(htsCode);
		setValue('hts_code', event.target.value.trim());
		setHtsCode(event.target.value.trim());
	};

	return (<>
        {!merchantProfile ? (
            <Navigate
                to="/merchant"
                state={{
                    snack: { type: 'warning', message: 'Please create a merchant profile before you continue' },
                    createMerchant: true
                }}
            />
        ) : (
            <div
                style={{
                    display: 'block',
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginBottom: '60px',
                    maxWidth: 1200,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
            >
                <Grid container alignItems="center" direction="column">
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" mt={4}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={8}
                            xl={8}
                            alignContent="center"
                            justifyContent="center"
                        >
                            <Typography
                                variant="h3"
                                id={createElementId([PRODUCT_LIBRARY, HEADING, 'product-library'])}
                            >
                                Product Library
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        spacing={2}
                        sx={{ marginBottom: '20px', marginTop: '30px' }}
                    >
                        <Grid item>
                            <Button
                                id={createElementId([PRODUCT_LIBRARY, BUTTON, 'add-product'])}
                                color="primary"
                                variant="contained"
                                sx={{ borderRadius: '30px' }}
                                onClick={() => setModalOpen(true)}
                            >
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>

                    {modalOpen && (
                        <Modal
                            open={modalOpen}
                            onClose={handleLeaveClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box
                                sx={isXsSmMd ? resetModalStyle : resetModalStyleSm}
                                style={{
                                    border: 'none',
                                    boxShadow: '1px 1px solid gray',
                                    overflow: 'auto',
                                    maxHeight: 800
                                }}
                            >
                                <Typography
                                    id={createElementId([PRODUCT_LIBRARY, MODAL, HEADING, 'add-custom-product'])}
                                    variant="h6"
                                    component="h2"
                                >
                                    Add Custom Product
                                </Typography>
                                <Typography
                                    id={createElementId([PRODUCT_LIBRARY, MODAL, 'description'])}
                                    sx={{ mt: 2, color: 'gray', fontSize: '14px' }}
                                >
                                    Reduce the risk of customs delays with the right harmonized code and a detailed
                                    item description (e.g. men’s cotton shirts).
                                </Typography>

                                <Box>
                                    <form>
                                        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                                            {showCustomsDescription && classificationResult === '' ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <TNAlerts
                                                        severity={
                                                            confidence < 0.35
                                                                ? 'warning'
                                                                : confidence < 0.5
                                                                ? 'warning'
                                                                : confidence < 0.71
                                                                ? 'warning'
                                                                : confidence < 0.9
                                                                ? 'info'
                                                                : 'success'
                                                        }
                                                        title={'Customs Description'}
                                                        message={customsDescription}
                                                        confidence={confidence}
                                                    />
                                                </Grid>
                                            ) : (
                                                classificationResult &&
                                                !customsDescription && (
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TNAlerts
                                                            severity="error"
                                                            title="HS Code Lookup Failed!"
                                                            message={classificationResult}
                                                        />
                                                    </Grid>
                                                )
                                            )}
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1 }}>
                                                {htsCodeError && codeError && (
                                                    <TNAlerts
                                                        severity="error"
                                                        title="Restricted Code"
                                                        message={
                                                            (
                                                                <>
                                                                    TUNL doesn't ship commodities under this HS/HTS
                                                                    code. For assistance or help, please contact{' '}
                                                                    <Link
                                                                        id={createElementId([
                                                                            PRODUCT_LIBRARY,
                                                                            MODAL,
                                                                            ALERT,
                                                                            LINK,
                                                                            'support'
                                                                        ])}
                                                                        href="mailto:support@tunl.to"
                                                                    >
                                                                        support@tunl.to
                                                                    </Link>
                                                                </>
                                                            ) as any
                                                        }
                                                    />
                                                )}
                                                {htsCodeWarning && codeWarning && (
                                                    <TNAlerts
                                                        severity="warning"
                                                        title="Warning Code"
                                                        message={
                                                            (
                                                                <>
                                                                    The commodities{' '}
                                                                    <strong>(HTS Code: {getHtsCode})</strong>{' '}
                                                                    requires permission or extra documentation to
                                                                    ship. Please get in touch with TUNL Support
                                                                    before proceeding.
                                                                </>
                                                            ) as any
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Tooltip title="Enter a detailed product description for customs e.g. men’s cotton shirts">
                                                {isXsSmMd ? (
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                                                            <InputLabel>Description</InputLabel>
                                                            <OutlinedInput
                                                                {...register('description', {
                                                                    required: 'Required'
                                                                })}
                                                                id={createElementId([
                                                                    PRODUCT_LIBRARY,
                                                                    MODAL,
                                                                    FORM_CONTROL,
                                                                    TEXTFIELD,
                                                                    'description'
                                                                ])}
                                                                error={errors.description ? true : false}
                                                                value={itemDescription}
                                                                onChange={e => {
                                                                    if (e.target.value === '') {
                                                                        setShowCustomsDescription(false);
                                                                    }
                                                                    setItemDescription(e.target.value);
                                                                }}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Button
                                                                            id={createElementId([
                                                                                PRODUCT_LIBRARY,
                                                                                MODAL,
                                                                                FORM_CONTROL,
                                                                                BUTTON,
                                                                                'get-hs-code'
                                                                            ])}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={classifyItem}
                                                                            disabled={
                                                                                isLoading || itemDescription === ''
                                                                            }
                                                                            style={{ marginBottom: '1px' }}
                                                                            sx={{ whiteSpace: 'nowrap' }}
                                                                        >
                                                                            Get HS Code
                                                                        </Button>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            {errors.description ? (
                                                                <FormHelperText error>
                                                                    Please fill in this field
                                                                </FormHelperText>
                                                            ) : null}
                                                        </FormControl>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                                                            <InputLabel>Description</InputLabel>
                                                            <OutlinedInput
                                                                {...register('description', {
                                                                    required: 'Required'
                                                                })}
                                                                id={createElementId([
                                                                    PRODUCT_LIBRARY,
                                                                    MODAL,
                                                                    FORM_CONTROL,
                                                                    TEXTFIELD,
                                                                    'description'
                                                                ])}
                                                                error={errors.description ? true : false}
                                                                value={itemDescription}
                                                                onChange={e => {
                                                                    if (e.target.value === '') {
                                                                        setShowCustomsDescription(false);
                                                                    }
                                                                    setItemDescription(e.target.value);
                                                                }}
                                                            />
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                                mt={2}
                                                            >
                                                                <Button
                                                                    id={createElementId([
                                                                        PRODUCT_LIBRARY,
                                                                        MODAL,
                                                                        FORM_CONTROL,
                                                                        BUTTON,
                                                                        'get-hs-code'
                                                                    ])}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={classifyItem}
                                                                    disabled={isLoading || itemDescription === ''}
                                                                    style={{ marginBottom: '1px' }}
                                                                    sx={{ whiteSpace: 'nowrap' }}
                                                                    fullWidth
                                                                >
                                                                    Get HS Code
                                                                </Button>
                                                            </Grid>
                                                            {errors.description ? (
                                                                <FormHelperText error>
                                                                    Please fill in this field
                                                                </FormHelperText>
                                                            ) : null}
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Tooltip>
                                            <Grid container spacing={1} sx={{ mt: 0, display: 'flex' }}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <InputLabel
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL_LABEL,
                                                            'Gender'
                                                        ])}
                                                    >
                                                        Gender
                                                    </InputLabel>
                                                    <Select
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            DROPDOWN,
                                                            SELECT,
                                                            'Gender'
                                                        ])}
                                                        fullWidth
                                                        value={itemGender}
                                                        onChange={e => setItemGender(e.target.value)}
                                                    >
                                                        <MenuItem
                                                            id={createElementId([
                                                                PRODUCT_LIBRARY,
                                                                MODAL,
                                                                FORM_CONTROL,
                                                                DROPDOWN,
                                                                SELECT,
                                                                MENU_ITEM,
                                                                "Boy's"
                                                            ])}
                                                            value="Boy's"
                                                        >
                                                            Boy's
                                                        </MenuItem>
                                                        <MenuItem
                                                            id={createElementId([
                                                                PRODUCT_LIBRARY,
                                                                MODAL,
                                                                FORM_CONTROL,
                                                                DROPDOWN,
                                                                SELECT,
                                                                MENU_ITEM,
                                                                "Girl's"
                                                            ])}
                                                            value="Girl's"
                                                        >
                                                            Girl's
                                                        </MenuItem>
                                                        <MenuItem
                                                            id={createElementId([
                                                                PRODUCT_LIBRARY,
                                                                MODAL,
                                                                FORM_CONTROL,
                                                                DROPDOWN,
                                                                SELECT,
                                                                MENU_ITEM,
                                                                "Men's"
                                                            ])}
                                                            value="Men's"
                                                        >
                                                            Men's
                                                        </MenuItem>
                                                        <MenuItem
                                                            id={createElementId([
                                                                PRODUCT_LIBRARY,
                                                                MODAL,
                                                                FORM_CONTROL,
                                                                DROPDOWN,
                                                                SELECT,
                                                                MENU_ITEM,
                                                                "Women's"
                                                            ])}
                                                            value="Women's"
                                                        >
                                                            Women's
                                                        </MenuItem>
                                                        <MenuItem
                                                            id={createElementId([
                                                                PRODUCT_LIBRARY,
                                                                MODAL,
                                                                FORM_CONTROL,
                                                                DROPDOWN,
                                                                SELECT,
                                                                MENU_ITEM,
                                                                'Other'
                                                            ])}
                                                            value="Other"
                                                        >
                                                            Other
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 2.9 }}>
                                                    <TextField
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            TEXTFIELD,
                                                            'Material'
                                                        ])}
                                                        label="Material"
                                                        fullWidth
                                                        inputProps={{ 'data-hj-allow': true }}
                                                        onChange={e => setItemMaterial(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ mt: 2 }} />
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            US customs identifier of goods.{' '}
                                                            <LearnMore
                                                                id={createElementId([
                                                                    PRODUCT_LIBRARY,
                                                                    MODAL,
                                                                    TOOLTIP,
                                                                    LINK,
                                                                    'hts-code-learn-more'
                                                                ])}
                                                                href="https://help.tunl.to/en/article/what-is-an-hs-code-and-hts-code-sez7qh/"
                                                                inline={true}
                                                                style={{ color: 'white' }}
                                                            />
                                                        </span>
                                                    }
                                                >
                                                    <TextField
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            TEXTFIELD,
                                                            'hts-code'
                                                        ])}
                                                        label="HTS Code"
                                                        {...register('hts_code', {
                                                            minLength: {
                                                                value: 10,
                                                                message:
                                                                    htsCodeError ||
                                                                    'HTS Code must at least be 10 digits'
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message:
                                                                    htsCodeError ||
                                                                    'HTS Code must not exceed 10 digits'
                                                            }
                                                        })}
                                                        required
                                                        value={getHtsCode}
                                                        onChange={(e: any) => {
                                                            handleHtsCodeChange(e);
                                                            handleHtsCodeWarning(e);
                                                        }}
                                                        error={!!errors.hts_code ? true : false || !!htsCodeError}
                                                        helperText={
                                                            errors.hts_code
                                                                ? (errors.hts_code.message as string)
                                                                : htsCodeError || ''
                                                        }
                                                        fullWidth
                                                        sx={{ mt: 2 }}
                                                        inputProps={{ 'data-hj-allow': true }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Tooltip title="Enter the product weight in kg e.g. 0.2">
                                                    <TextField
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            TEXTFIELD,
                                                            'unit-weight'
                                                        ])}
                                                        type="number"
                                                        label="Weight"
                                                        {...register('unit_weight', {
                                                            pattern: {
                                                                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                                                message: 'Weight must have up to 2 decimal places'
                                                            }
                                                        })}
                                                        inputProps={{
                                                            min: 0.1,
                                                            step: '0.01',
                                                            'data-hj-allow': true
                                                        }}
                                                        error={errors.unit_weight ? true : false}
                                                        helperText={
                                                            errors.unit_weight
                                                                ? (errors.unit_weight.message as string)
                                                                : ''
                                                        }
                                                        fullWidth
                                                        sx={{ mt: 2 }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Tooltip title="Enter the product value in the currency you use on your invoice e.g. 1000.">
                                                    <TextField
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            TEXTFIELD,
                                                            'unit-price'
                                                        ])}
                                                        type="number"
                                                        label="Value"
                                                        {...register('unit_price', {
                                                            pattern: {
                                                                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                                                                message: 'Value must have up to 2 decimal places'
                                                            }
                                                        })}
                                                        inputProps={{
                                                            step: '0.01',
                                                            'data-hj-allow': true
                                                        }}
                                                        error={errors.unit_price ? true : false}
                                                        helperText={
                                                            errors.unit_price
                                                                ? (errors.unit_price.message as string)
                                                                : ''
                                                        }
                                                        fullWidth
                                                        sx={{ mt: 2 }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Tooltip title="Enter the country code where the product was last manufactured or underwent significant alterations. E.g. use 'ZA' for South Africa.">
                                                    <TextField
                                                        id={createElementId([
                                                            PRODUCT_LIBRARY,
                                                            MODAL,
                                                            FORM_CONTROL,
                                                            TEXTFIELD,
                                                            'origin-country'
                                                        ])}
                                                        required
                                                        fullWidth
                                                        label="Country of Origin"
                                                        sx={{ mt: 2 }}
                                                        {...register('origin_country', {
                                                            required: 'Required',
                                                            pattern: {
                                                                value: /^[a-z][A-Z]{1}$/i,
                                                                message:
                                                                    'Invalid input. Country must be 2 letter code'
                                                            }
                                                        })}
                                                        error={!!errors?.origin_country}
                                                        helperText={
                                                            errors?.origin_country
                                                                ? 'Invalid input. Country must be 2 letter code'
                                                                : null
                                                        }
                                                        inputProps={{ 'data-hj-allow': true }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        flexDirection: 'row',
                                        pt: 2
                                    }}
                                >
                                    <Button
                                        id={createElementId([PRODUCT_LIBRARY, MODAL, BUTTON, 'cancel'])}
                                        onClick={handleLeaveClose}
                                        sx={{ mr: 1 }}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        id={createElementId([PRODUCT_LIBRARY, MODAL, BUTTON, 'save'])}
                                        onClick={() => {
                                            htsCodeError === '' ? handleSubmit(addProduct)() : setAlertOpen(true);
                                        }}
                                        sx={{ mr: 1 }}
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>
                    )}
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        sx={{ marginBottom: '20px', marginTop: '30px' }}
                    >
                        <Grid item width="100%">
                            <ProductsTable products={products} updateProduct={updateProduct} />
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    open={openSnackBar}
                    autoHideDuration={2000}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
                        {apiResponse.message}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={2000}
                    onClose={() => {
                        setAlertOpen(false);
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => {
                            setAlertOpen(false);
                        }}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        {htsCodeError}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={message !== '' ? updateOpen : !updateOpen}
                    autoHideDuration={2000}
                    onClose={() => setUpdateOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
                        {snackAlert.message}
                    </Alert>
                </Snackbar>
                <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )}
    </>);
}
