import { Card, CardContent, Grid, Typography } from '@mui/material';
import { TrackerRecord } from '../../types/tracker';
import { CONTENT_CARD, createElementId, LABEL, TRACKER, TRACKING_DETAILS } from '../../constants/id';

export default function TrackingDetail(props: { trackingDetails: any }) {
	return (
		<Card
			variant="outlined"
			sx={{
				border: 0,
				borderTop: 1,
				borderBottom: 0,
				borderLeft: 0,
				borderRight: 0,
				borderRadius: '0px',
				borderColor: '#dedede',
				marginBottom: -1
			}}
		>
			<Grid container direction="row">
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
					<CardContent>
						{props.trackingDetails.datetime ? (
							<>
								<Typography
									id={createElementId([TRACKER, TRACKING_DETAILS, CONTENT_CARD, LABEL, 'date'])}
									variant="body1"
								>
									{new Date(props.trackingDetails.datetime).toDateString()}
								</Typography>
								<Typography
									id={createElementId([TRACKER, TRACKING_DETAILS, CONTENT_CARD, LABEL, 'time'])}
									variant="body2"
								>
									{new Date(props.trackingDetails.datetime).toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit'
									})}
								</Typography>
							</>
						) : (
							<></>
						)}
					</CardContent>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
					<Grid container direction="row">
						<Grid item>
							<CardContent>
								<Typography
									id={createElementId([TRACKER, TRACKING_DETAILS, CONTENT_CARD, LABEL, 'message'])}
									variant="body1"
									fontWeight="fontWeightBold"
								>
									{props.trackingDetails.message}
								</Typography>
								<Typography
									id={createElementId([TRACKER, TRACKING_DETAILS, CONTENT_CARD, LABEL, 'location'])}
									variant="body1"
								>
									{props.trackingDetails.tracking_location.city
										? props.trackingDetails.tracking_location.city +
										  ', ' +
										  props.trackingDetails.tracking_location.state
										: ''}
								</Typography>
								<Typography variant="body2"></Typography>
							</CardContent>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
}
