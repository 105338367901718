import { Alert, Backdrop, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosConfig } from '../../../constants/axios';
import { useMerchant } from '../../../context/MerchantContext';
import { SnackAlert } from '../../../types/util';
import ConnectShopify from './Shopify/ConnectShopify';
import SelectCheckoutShippingMethods from './Shopify/SelectCheckoutShippingMethods';

export default function SalesChannels(props: any) {
	const [loading, setLoading] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [apiResponse, setApiResponse] = useState<SnackAlert>({ type: 'success', message: '' });
	const { merchantProfile, setMerchantProfile } = useMerchant();
	const [shopifyCarrier, setShopifyCarrier] = useState(merchantProfile.merchant_settings?.shopify_carrier);
	const [connected, setConnected] = useState(false);
	const [initCheck, setInitCheck] = useState(false);
	const axiosInstance = axiosConfig();
	const navigate = useNavigate();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		const shop = urlParams.get('shop');
		const hmac = urlParams.get('hmac');
		const state = urlParams.get('state');
		const client_id = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
		const client_secret = process.env.REACT_APP_SHOPIFY_CLIENT_SECRET;

		const exchangeAccessToken = async (code: string, shop: string, client_id: string, client_secret: string) => {
			try {
				setLoading(true);
				const response = await axiosInstance.post(`${process.env.REACT_APP_API_BASEURL}/shopify/connect/`, {
					code: code,
					shop: shop,
					client_id: client_id,
					client_secret: client_secret,
					merchant_id: state
				});
				setMerchantProfile(response.data);
				setApiResponse({ type: 'success', message: 'Success' });
				setOpenSnackBar(true);
				setShopifyCarrier(true);
				setConnected(true);
				setLoading(false);
				sessionStorage.setItem('merchant', JSON.stringify(response.data));
				navigate('/live-rates', { replace: true });
			} catch (error) {
				setApiResponse({
					type: 'error',
					message: 'Error: An error occurred while connecting to Shopify'
				});
				setOpenSnackBar(true);
				setLoading(false);
			}
		};

		if (code && state && shop && client_id && client_secret) {
			exchangeAccessToken(code, shop, client_id, client_secret);
		}
	}, []);

	useEffect(() => {
		setShopifyCarrier(merchantProfile.merchant_settings?.shopify_carrier);
		setConnected(merchantProfile.merchant_settings?.shopify_carrier);
	}, [merchantProfile]);

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	return (
		<div
			style={{
				display: 'block',
				justifyContent: 'center',
				alignContent: 'center',
				marginBottom: '60px',
				maxWidth: 1200,
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<Grid container direction="column" alignContent="center" justifyContent="center" lineHeight={5}>
				<Grid item textAlign="center">
					<Typography variant="h5" my={5}>
						Get Live Rates On Your Shopify Store
					</Typography>
				</Grid>
				{shopifyCarrier && !connected ? (
					<SelectCheckoutShippingMethods initCheck={initCheck} setInitCheck={setInitCheck} />
				) : shopifyCarrier ? (
					<SelectCheckoutShippingMethods initCheck={initCheck} setInitCheck={setInitCheck} />
				) : (
					<ConnectShopify
						merchantProfile={merchantProfile}
						setMerchantProfile={setMerchantProfile}
						shopifyCarrier={shopifyCarrier}
						setShopifyCarrier={setShopifyCarrier}
						setConnected={setConnected}
						setLoading={setLoading}
						setOpenSnackBar={setOpenSnackBar}
						setApiResponse={setApiResponse}
						setInitCheck={setInitCheck}
					/>
				)}
			</Grid>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
					{apiResponse.message}
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
