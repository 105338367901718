import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { MerchantRecord } from '../../../types/merchant';
import { Shipment } from '../../../types/shipment';
import { currencyToSymbolConversion } from '../../../util/currency';
import { kgToOzConversion } from '../../../util/parcel';

const styles = StyleSheet.create({
	page: { padding: 15, marginTop: 15 },
	title: { fontSize: 14, textAlign: 'center', marginBottom: 10, fontWeight: 'bold' },
	section: { border: '1px solid black' },
	row: { display: 'flex', flexDirection: 'row' },
	column: { flex: 1, border: '1px solid black', padding: 15 },
	smallColumn: { flex: 0.2, padding: 2, alignItems: 'center', justifyContent: 'center' },
	largeColumn: { flex: 0.5, padding: 2, alignItems: 'center', justifyContent: 'center' },
	headerText: { fontSize: 10, fontWeight: 'bold', marginBottom: 2, paddingBottom: 15 },
	smallText: { fontSize: 7, marginBottom: 2, noWrap: true, paddingBottom: 15 },
	text: { fontSize: 12, textAlign: 'center' },
	checkbox: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
	tableHeader: {
		borderBottomWidth: 1,
		borderBottomColor: '#000'
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'row',
		padding: 5,
		borderBottomWidth: 1,
		borderBottomColor: '#000'
	},
	tableCell: {
		borderBottomWidth: 0,
		borderBottonColor: '#000'
	},
	tableCellText: {
		padding: 0
	}
});

interface InvoiceInfo {
	shipment: Shipment;
	merchant: MerchantRecord;
}

export default function GenerateCommercialInvoice({ shipment, merchant }: InvoiceInfo) {
	const billingInfo = {
		taxId: merchant?.merchant_settings?.use_own_tax_id ? merchant?.tax_identifiers[0].tax_id : '',
		contactName: shipment?.address_from_name,
		phone: shipment?.address_from_phone,
		email: shipment?.address_from_email,
		companyAddress: `${merchant.merchant_street_1} ,  ${merchant?.merchant_city_locality}`
	};

	const consigneeInfo = {
		taxId: '',
		contactName: shipment?.address_to_name,
		phone: shipment?.address_to_phone,
		email: shipment?.address_to_email,
		companyAddress: `${shipment?.address_to_street_1} , ${shipment?.address_to_city_locality}`
	};

	const shipmentInfo = {
		airWaybill: shipment?.carrier_track_code,
		invoiceNo: shipment?.shipment_reference,
		date: new Date(shipment?.date_created).toDateString(),
		exportersCode: merchant?.merchant_exporters_code,
		incoterm: shipment?.customs_incoterm ? 'DAP' : 'DAP'
	};

	const items =
		shipment.customs_info?.customs_items?.map((item: any) => ({
			currencySymbol: currencyToSymbolConversion(item?.currency),
			packages: shipment?.customs_info?.contents_type,
			units: Math.round(item?.quantity),
			netWeight: kgToOzConversion(item?.weight),
			unitMeasure: 'Kg',
			description: item?.description,
			tariffNumber: item?.hs_tariff_number,
			country: item?.currency,
			unitValue: item?.value ? Number(item?.value)?.toFixed(2) : 0
		})) || [];

	return (
		<Document>
			<Page style={styles.page}>
				<Text style={styles.title}>COMMERCIAL INVOICE</Text>

				{/* Exporter section */}
				<View style={styles.row}>
					<View style={styles.column}>
						<View style={styles.row}>
							<Text style={styles.headerText}>EXPORTER:</Text>
						</View>
						<Text style={styles.smallText}>Tax ID#: {billingInfo.taxId}</Text>
						<Text style={styles.smallText}>Contact Name: {billingInfo.contactName}</Text>
						<Text style={styles.smallText}>Telephone No.: {billingInfo.phone}</Text>
						<Text style={styles.smallText}>Exporters Code: {shipmentInfo.exportersCode}</Text>
						<Text style={styles.smallText}>E-Mail: {billingInfo.email}</Text>
						<Text style={styles.smallText}>Company Name/Address: {billingInfo.companyAddress}</Text>
					</View>
					<View style={styles.column}>
						<View style={styles.row}>
							<Text style={styles.headerText}>SHIPMENT INFO:</Text>
						</View>
						<Text style={styles.smallText}>Air Waybill No.: {shipmentInfo.airWaybill}</Text>
						<Text style={styles.smallText}>Invoice No.: {shipmentInfo.invoiceNo}</Text>
						<Text style={styles.smallText}>Ship Date: {shipmentInfo.date}</Text>
						<Text style={styles.smallText}>Incoterm: {shipmentInfo.incoterm}</Text>
					</View>
				</View>

				{/* Consignee section */}
				<View style={styles.row}>
					<View style={styles.column}>
						<View style={styles.row}>
							<Text style={styles.headerText}>CONSIGNEE:</Text>
						</View>
						<Text style={styles.smallText}>Tax ID#: {consigneeInfo.taxId}</Text>
						<Text style={styles.smallText}>Contact Name: {consigneeInfo.contactName}</Text>
						<Text style={styles.smallText}>Telephone No.: {consigneeInfo.phone}</Text>
						<Text style={styles.smallText}>E-Mail: {consigneeInfo.email}</Text>
						<Text style={styles.smallText}>Company Name/Address: {consigneeInfo.companyAddress}</Text>
					</View>
					<View style={styles.column}>
						<View style={styles.row}>
							<Text style={styles.headerText}>SOLD TO / IMPORTER:</Text>
						</View>
						<Text style={styles.smallText}>Tax ID#: {consigneeInfo.taxId}</Text>
						<Text style={styles.smallText}>Company Name/Address: {consigneeInfo.companyAddress}</Text>
					</View>
				</View>

				{/* Items section */}
				<View style={styles.section}>
					<View style={[styles.tableRow, styles.tableHeader]}>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Purpose of Shipment</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>No. of Units</Text>
						</View>
						<View style={[styles.largeColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Description of Goods</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Harmonized Tariff Number</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Country Code</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Net Weight (Kg)</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>Total Value</Text>
						</View>
					</View>

					{items.map((item: any, index: any) => (
						<View key={index} style={[styles.tableRow]}>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>{item.packages}</Text>
							</View>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>{item.units}</Text>
							</View>
							<View style={[styles.largeColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>{item.description}</Text>
							</View>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>{item.tariffNumber}</Text>
							</View>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>{item.country}</Text>
							</View>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}></Text>
							</View>
							<View style={[styles.smallColumn, styles.tableCell]}>
								<Text style={[styles.smallText, styles.tableCellText]}>
									{item.currencySymbol}
									{`${new Intl.NumberFormat('en-US', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2
									}).format(item.unitValue ?? 0)}`}
								</Text>
							</View>
						</View>
					))}

					{/* Total row */}
					<View style={[styles.tableRow]}>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.text, styles.tableCellText]}>Total</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.text, styles.tableCellText]}></Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.text, styles.tableCellText]}></Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.text, styles.tableCellText]}></Text>
						</View>
						<View style={[styles.largeColumn, styles.tableCell]}>
							<Text style={[styles.text, styles.tableCellText]}></Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>
								{`${new Intl.NumberFormat('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}).format(
									items.reduce(
										(total: number, item: any) => total + (parseFloat(item.netWeight) ?? 0),
										0
									)
								)}`}
							</Text>
						</View>
						<View style={[styles.smallColumn, styles.tableCell]}>
							<Text style={[styles.smallText, styles.tableCellText]}>
								{items.length > 0 && items[0].currencySymbol}
								{`${new Intl.NumberFormat('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								}).format(
									items.reduce(
										(total: number, item: any) => total + (parseFloat(item.unitValue) ?? 0),
										0
									)
								)}`}
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
}
