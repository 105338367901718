import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputBase,
	MenuItem,
	Select,
	Snackbar,
	styled,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddressAutoComplete from '../../components/Merchant/Shipment/CreateShipmentForm/AddressAutoComplete';
import { axiosConfig } from '../../constants/axios';
import { UserRecord } from '../../types/user';
import { SnackAlert } from '../../types/util';

export default function MerchantProfile(props: any) {
	const user: UserRecord = JSON.parse(
		sessionStorage.getItem('user') ? (sessionStorage.getItem('user') as string) : '{}'
	);
	const merchant = JSON.parse('merchant' in sessionStorage ? (sessionStorage.getItem('merchant') as string) : '{}');
	const location = useLocation();
	const { snack, createMerchant } = {
		...(location.state as { snack: { type: string; message: string }; createMerchant: boolean })
	};
	// const snack = location.state ? (location.state as { type: string; message: string }) : null;
	const [loading, setLoading] = useState(false);
	const [merchant_info, setMerchantInfo] = useState(merchant);
	const [countryCode, setCountryCode] = useState('');
	const [openSnackBar, setOpenSnackBar] = useState(false);
	// const [checked, setCheckbox] = useState({
	// 	drop_off: false,
	// 	collect: false
	// });
	const [idType, setIdType] = useState<0 | 1>(0);
	const [apiResponse, setApiResponse] = useState<SnackAlert>({ type: 'success', message: '' });
	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		'label + &': {
			marginTop: theme.spacing(3)
		},
		'& .MuiInputBase-input': {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		}
	}));
	const [merchant_address, setMerchantAddressDetails] = useState({
		merchant_street_1: '',
		merchant_zip_postal: '',
		merchant_city_locality: '',
		merchant_state_province: '',
		merchant_country: ''
	});
	const axiosInstance = axiosConfig();

	useEffect(() => {
		// setCheckbox({
		// 	drop_off: merchant_info.merchant_dropoff,
		// 	collect: merchant_info.merchant_collect
		// });
		if (snack) {
			setApiResponse({ ...(snack as SnackAlert) });
			setOpenSnackBar(true);
		}
	}, []);

	const handleChange = (event: any) => {
		setIdType(event.target.value);
	};

	const {
		register,
		watch,
		setValue,
		trigger,
		formState: { errors },
		handleSubmit
	} = useForm({ mode: 'all', defaultValues: { ...merchant_info, user_id: user.user_id, merchant_id: uuidv4() } });

	const handleFormSubmit = (event: any) => {
		setLoading(true);
		axiosInstance
			.post('/merchant/createMerchant', [event])
			.then(response => {
				props.setMerchant(response.data.merchantRecords[0]);
				sessionStorage.setItem('merchant', JSON.stringify(response.data.merchantRecords[0]));
				setApiResponse({
					type: 'success',
					message: 'Successfully Updated Profile'
				});
				setOpenSnackBar(true);
				setLoading(false);
			})
			.catch(err => {
				setApiResponse({
					type: 'error',
					message: 'Error: Failed to update profile'
				});
				setOpenSnackBar(true);
				setLoading(false);
				console.log(err);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		if (merchant_address.merchant_street_1 || merchant_address.merchant_country) {
			setValue('merchant_street_1', merchant_address.merchant_street_1);
			setValue('merchant_zip_postal', merchant_address.merchant_zip_postal);
			setValue('merchant_city_locality', merchant_address.merchant_city_locality);
			setValue('merchant_state_province', merchant_address.merchant_state_province);
			setValue('merchant_country', merchant_address.merchant_country);
			trigger('merchant_street_1');
			trigger('merchant_zip_postal');
			trigger('merchant_city_locality');
			trigger('merchant_state_province');
			trigger('merchant_country');
		}
	}, [merchant_address]);

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	const handleCountryAddressChange = (e: React.ChangeEvent<{}>, value: any) => {
		if (value) {
			const selectedOption = e.target as HTMLElement;
			const optionText = selectedOption.textContent || '';
			const code = optionText.substring(optionText.lastIndexOf(' ') + 1);
			setCountryCode(code);
			setValue('merchant_country', code);
		}
	};

	const handleAddressChange = (e: any) => {
		const { name, value } = e.target;
		setValue(name, value);
		trigger(name);
	};

	const isToAddressFilled = () => {
		return (
			merchant_address.merchant_street_1?.trim() !== '' ||
			merchant_address.merchant_city_locality?.trim() !== '' ||
			merchant_address.merchant_state_province?.trim() !== '' ||
			merchant_address.merchant_zip_postal?.trim() !== '' ||
			merchant_address.merchant_country?.trim() !== ''
		);
	};
	const isAddressFormFilled = isToAddressFilled();

	return (
        (<div
                                                                                                                                                                                                                                                                                                                                        style={{
                                                                                                                                                                                                                                                                                                                                            display: 'block',
                                                                                                                                                                                                                                                                                                                                            justifyContent: 'center',
                                                                                                                                                                                                                                                                                                                                            alignContent: 'center',
                                                                                                                                                                                                                                                                                                                                            marginBottom: '60px',
                                                                                                                                                                                                                                                                                                                                            maxWidth: 1200,
                                                                                                                                                                                                                                                                                                                                            marginLeft: 'auto',
                                                                                                                                                                                                                                                                                                                                            marginRight: 'auto'
                                                                                                                                                                                                                                                                                                                                        }}
                                                                                                                                                                                                                                                                                                                                    >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
				<Grid container direction="row" alignContent="center" justifyContent="center" lineHeight={5}>
					<Typography variant="h5" mb={5} mt={5}>
						Create Merchant
					</Typography>
					<Grid container spacing={2} justifyContent="center" alignContent="center">
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							<TextField
								required
								label="Company Name"
								fullWidth
								{...register('merchant_name', { required: 'Required' })}
								error={!!errors?.merchant_name}
								helperText={errors?.merchant_name ? (errors.merchant_name.message as string) : null}
							/>
						</Grid>
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							<TextField
								required
								label="Email"
								{...register('merchant_email', {
									required: 'Required',
									pattern: {
										value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
										message: 'Invalid email address'
									}
								})}
								error={!!errors?.merchant_email}
								helperText={errors?.merchant_email ? (errors.merchant_email.message as string) : null}
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} justifyContent="center" alignContent="center">
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							<TextField
								required
								label="Phone"
								{...register('merchant_phone', {
									required: 'Required',
									pattern: {
										value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
										message: 'Invalid Phone number'
									}
								})}
								helperText={errors?.merchant_phone ? (errors.merchant_phone.message as string) : null}
								fullWidth
							/>
						</Grid>
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							<TextField
								label="Vat Number"
								fullWidth
								{...register('merchant_vat_number', {})}
								error={!!errors?.merchant_vat_number}
								helperText={
									errors?.merchant_vat_number ? (errors.merchant_vat_number.message as string) : null
								}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							<FormControl variant={'standard'} fullWidth>
								<Select value={idType} onChange={handleChange} input={<BootstrapInput />} fullWidth>
									<MenuItem value={0}>Exporter's Code</MenuItem>
									<MenuItem value={1}>ID/Passport Number</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
							{idType === 0 ? (
								<TextField
									required
									label="Exporter's Code"
									{...register('merchant_exporters_code', { required: 'Required' })}
									helperText={
										errors?.merchant_exporters_code
											? (errors.merchant_exporters_code.message as string)
											: null
									}
									fullWidth
								/>
							) : (
								<TextField
									required
									label="ID/Passport Number"
									{...register('merchant_personal_id', { required: 'Required' })}
									helperText={
										errors?.merchant_personal_id
											? (errors.merchant_personal_id.message as string)
											: null
									}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>

					<Grid container spacing={2} justifyContent="center" alignContent="center">
						<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
							<AddressAutoComplete
								setMerchantAddressDetails={setMerchantAddressDetails}
							></AddressAutoComplete>
						</Grid>
					</Grid>
					{isAddressFormFilled && (
						<>
							<Grid container spacing={2} justifyContent="center" alignContent="center">
								<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
									<TextField
										required
										label="Address Line 1"
										{...register('merchant_street_1', { required: 'Required' })}
										helperText={
											errors?.merchant_street_1
												? (errors.merchant_street_1.message as string)
												: null
										}
										fullWidth
										value={watch('merchant_street_1') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={4} xl={4} mb={2}>
									<TextField
										label="Address Line 2"
										fullWidth
										{...register('merchant_street_2', {})}
										helperText="Apartment, building, floor(optional)"
										value={watch('merchant_street_2') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} justifyContent="center" alignContent="center">
								<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
									<TextField
										required
										label="City"
										fullWidth
										{...register('merchant_city_locality', {
											required: 'Required'
										})}
										error={!!errors?.merchant_city_locality}
										helperText={
											errors?.merchant_city_locality
												? (errors.merchant_city_locality.message as string)
												: null
										}
										value={watch('merchant_city_locality') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
									<TextField
										required
										label="Province"
										fullWidth
										{...register('merchant_state_province', {
											required: 'Required'
										})}
										error={!!errors?.merchant_state_province}
										helperText={
											errors?.merchant_state_province
												? (errors.merchant_state_province.message as string)
												: null
										}
										value={watch('merchant_state_province') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={8} md={5} lg={2} xl={2}>
									<TextField
										required
										label="Postal Code"
										fullWidth
										{...register('merchant_zip_postal', {
											required: 'Required'
										})}
										error={!!errors?.merchant_zip_postal}
										helperText={
											errors?.merchant_zip_postal
												? (errors.merchant_zip_postal.message as string)
												: null
										}
										value={watch('merchant_zip_postal') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Grid>
								<Grid item xs={10} sm={8} md={5} lg={2} xl={2} mb={2}>
									<TextField
										required
										label="Country"
										fullWidth
										{...register('merchant_country', {
											required: 'Required',
											pattern: {
												value: /^[a-z][A-Z]{1}$/i,
												message: 'Invalid input. Country must be 2 letter code'
											}
										})}
										error={!!errors?.merchant_country}
										helperText={
											errors?.merchant_country
												? (errors.merchant_country.message as string)
												: null
										}
										value={watch('merchant_country') || ''}
										onChange={handleAddressChange}
										inputProps={{ 'data-hj-allow': true }}
									/>
									{/* <Autocomplete
										fullWidth
										options={countries.map((item: { code: string; name: string }) => ({
											name: `${item.name} ${item.code}`,
											code: item.code
										}))}
										getOptionLabel={option => option.name as string}
										onChange={handleCountryAddressChange}
										value={countries.find(item => item.code === watch('merchant_country')) || null}
										renderInput={params => (
											<TextField
												{...params}
												required
												label="Country"
												error={!!errors?.merchant_country}
												helperText={
													errors?.merchant_country
														? (errors.merchant_country.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
									/>
										)}
									/> */}
								</Grid>
							</Grid>
						</>
					)}
					<Grid container spacing={2} justifyContent="center" alignContent="center">
						<Grid item>
							<Button variant="contained" type="submit">
								Create Merchant
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
            <Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
					{apiResponse.message}
				</Alert>
			</Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
        </div>)
    );
}

/**
 * 
	export type ShipmentData = {	
		insurance: string;
		invoice: string;
		reference: string;
		product_reference: string;
		customs_info: CustomsInfo;
		rate_id: string;
		service: string;
		carrier_accounts: [string];
		shipment_type: 'economy' | 'express';
		rate: string;
		carrier: string;
		customs_incoterm: string;
		delivery_confirmation: boolean;
	};
--------------------------------------------------------------
	customs_info: {
				customs_certify: true,
				customs_signer: '',
				contents_type: 'merchandise',
				contents_explanation: '',
				restriction_type: 'none',
				restriction_comments: '',
				eel_pfc: '',
				customs_items: [
					{
						description: '',
						quantity: 1,
						weight: 0,
						value: 0,
						hs_tariff_number: '',
						origin_country: 'ZA',
						currency: undefined,
						save_customs_line_item: false
					}
				]
			}
--------------------------------------------------------------
"customs_info": {
        "customs_certify": true,
        "customs_signer": "",
        "contents_type": "merchandise",
        "contents_explanation": "",
        "restriction_type": "none",
        "restriction_comments": "",
        "eel_pfc": "",
        "customs_items": [
            {
                "description": "Black Cotton T-Shirt Male",
                "quantity": 1,
                "weight": 2,
                "value": 123,
                "hs_tariff_number": "6109100004",
                "origin_country": "ZA",
                "currency": "USD",
                "save_customs_line_item": false,
                "sortKey": "66950885992fa9c545bc0b8f"
            }
        ]
    },
 */
