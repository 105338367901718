import { Box, Grid, Paper, Radio, Typography } from '@mui/material';

export interface MockShopifyRateOption {
	id: string;
	carrier: string;
	description: string;
	price: string;
	deliveryTime: string;
	dutiesIncluded: boolean;
	dutiesAmount?: string;
}

export interface MockShopifyRateListProps {
	options: Array<MockShopifyRateOption>;
}

export const rateOptions: Array<MockShopifyRateOption> = [
	{
		id: '1',
		carrier: 'FedEx',
		description: 'International Priority',
		price: 'RR',
		deliveryTime: '5 - 7 days',
		dutiesIncluded: false
	},
	{
		id: '2',
		carrier: 'FedEx',
		description: 'International Priority',
		price: 'RRR',
		deliveryTime: '4 - 6 days',
		dutiesIncluded: true,
		dutiesAmount: 'R'
	},
	{
		id: '3',
		carrier: 'UPS',
		description: 'Express Saver',
		price: 'RR',
		deliveryTime: '5 - 7 days',
		dutiesIncluded: false
	},
	{
		id: '4',
		carrier: 'UPS',
		description: 'Express Saver',
		price: 'RRR',
		deliveryTime: '4 - 6 days',
		dutiesIncluded: true,
		dutiesAmount: 'R'
	},
	{
		id: '5',
		carrier: 'Economy',
		description: ' shipping with UPS Ground',
		price: 'R',
		deliveryTime: ' 10 - 20 days',
		dutiesIncluded: true,
		dutiesAmount: 'R'
	}
];

export default function ShippingRateList(props: MockShopifyRateListProps) {
	return (
		<Box>
			{props.options.map(option => (
				<Paper key={option.id} variant="outlined" sx={{ padding: 2 }}>
					<Grid container direction="row" spacing={1}>
						<Grid item xs={1}>
							<Radio
								sx={{ py: 0, verticalAlign: 'top' }}
								disabled={true}
								value={option.id}
								name="rate-options"
								inputProps={{ 'aria-label': option.description }}
							/>
						</Grid>
						<Grid item container xs={11}>
							<Grid item container direction="row" justifyContent={'space-between'} sx={{ pb: 0 }}>
								<Grid item>
									<Typography fontWeight="bold">
										{option.carrier} {option.description}{' '}
										{option.dutiesIncluded
											? '[Duties & Taxes Included]'
											: '[Duties & Taxes Not Included]'}
									</Typography>
								</Grid>
								<Grid item>
									<Typography fontWeight="bold">R{option.price}</Typography>
								</Grid>
							</Grid>
							<Grid item>
								<Typography color="textSecondary">
									Delivery time: {option.deliveryTime};{' '}
									{option.dutiesIncluded
										? `Duties & Taxes: R${option.dutiesAmount}`
										: 'Duties and taxes plus additional fees will be billed on delivery.'}{' '}
									Amount {option.dutiesIncluded ? 'guaranteed.' : 'not guaranteed.'}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			))}
		</Box>
	);
}
