import {
	Alert,
	AlertTitle,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Paper,
	Snackbar,
	styled,
	TextField,
	Typography
} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import TrackingDetail from '../../components/Tracker/TrackingDetail';
import { axiosConfig } from '../../constants/axios';
import { BUTTON, createElementId, FORM_CONTROL, LABEL, PAPER, TEXTFIELD, TRACKER } from '../../constants/id';
import { TrackingDetails, TrackingStatus } from '../../types/tracker';
import { SnackAlert } from '../../types/util';
import TNAlerts from '../../components/Common/TNAlerts';

export default function TrackerMerchant(trackingCode?: any) {
	const steps = ['Pre transit', 'In transit', 'Out for delivery', 'Delivered'];
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [textTrackingId, setTextTrackingId] = useState('');
	const [tracker, setTracker] = useState<TrackingDetails[]>();
	const [easypostTracker, setEasypostTracker] = useState<any>();
	const [carrierTrackCode, setCarrierTrackCode] = useState('');
	let trackerId = useParams<{ trackerId: string }>().trackerId || trackingCode.trackingCode;
	const axiosInstance = axiosConfig();

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	function fetchTrackerRecord(event: any) {
		setLoading(true);
		trackerId = event;
		axiosInstance
			.post('/tracker/getTrackerRecordsById', { trackerId: trackerId })
			.then(response => {
				let redirectTrackingDetail = null;

				if (response.data.data.tracking_details.length > 1) {
					redirectTrackingDetail = response.data.data.tracking_details.find(
						(trackingDetail: any) =>
							trackingDetail.status_detail === 'received_at_origin_facility' ||
							trackingDetail.status_detail === 'arrived_at_facility'
					);
				}
				if (redirectTrackingDetail || response.data.data.shipment_type === 'Express Shipment') {
					if (
						(response.data.data.carrier === 'UPS' || response.data.data.carrier === 'UPSDAP') &&
						trackingCode.trackingCode === undefined
					) {
						window.open('https://www.ups.com/track?tracknum=' + response.data.data.tracking_code, '_blank');
					} else if (response.data.data.carrier === 'FedEx' && trackingCode.trackingCode === undefined) {
						window.open(
							'https://www.fedex.com/fedextrack/?trknbr=' + response.data.data.tracking_code,
							'_blank'
						);
					} else if (trackingCode.trackingCode === undefined) {
						window.open(
							'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=' +
								response.data.data.tracking_code,
							'_blank'
						);
					}
				}
				response.data.data.tracking_details.sort((a: any, b: any) => {
					const dateA = new Date(a.datetime);
					const dateB = new Date(b.datetime);
					return dateB.getTime() - dateA.getTime();
				});

				setEasypostTracker(response.data.data);
				setTracker(response.data.data?.tracking_details);
				const type = response.data.message === 'Success' ? 'success' : 'error';
				setSnackAlert({ type: type, message: response.data.message });
				setOpenSnackBar(true);
				setLoading(false);
			})
			.catch(error => {
				if (axios.isAxiosError(error) && error.response) {
					setSnackAlert({ type: 'error', message: error.response.data.message });
					setOpenSnackBar(true);
					setLoading(false);
				} else {
					setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
					setLoading(false);
				}
				console.log(error);
				setLoading(false);
			});
	}

	useEffect(() => {
		if (trackerId !== undefined) {
			fetchTrackerRecord(trackerId);
		}
	}, []);

	useEffect(() => {
		if (easypostTracker !== undefined && Array.isArray(tracker)) {
			setCarrierTrackCode(easypostTracker.tracking_code);
			tracker.some(trackingDetail => trackingDetail.status.toLowerCase() === TrackingStatus.Delivered)
				? setActiveStep(4)
				: tracker.some(trackingDetail => trackingDetail.status.toLowerCase() === TrackingStatus.OutForDelivery)
				? setActiveStep(2)
				: tracker.some(trackingDetail => trackingDetail.status.toLowerCase() === TrackingStatus.InTransit)
				? setActiveStep(1)
				: tracker.some(trackingDetail => trackingDetail.status.toLowerCase() === TrackingStatus.PreTransit)
				? setActiveStep(0)
				: setActiveStep(0);
			setLoading(false);
		}
	}, [tracker]);

	const Img = styled('img')({
		maxWidth: '70px',
		maxHeight: '70px',
		paddingBlock: '10px',
		justifySelf: 'flex-end',
		justify: 'flex-end'
	});

	// const [onlyItem] = tracker;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginTop: '15px',
				marginLeft: 'auto',
				marginRight: 'auto',
				maxWidth: 800
			}}
		>
			<Grid container direction="row" justifyContent={'center'}>
				{/* Input Field Section */}
				{trackingCode.trackingCode === undefined && (
					<Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3}>
						<Grid
							container
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Grid mt={2} alignItems="center" justifyContent="center">
								<Typography variant="h4">
									<strong id={createElementId([TRACKER, LABEL, 'searchLabel'])}>
										Search for your shipment
									</strong>
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							alignSelf={'center'}
							justifyContent={'center'}
						>
							<Paper
								elevation={5}
								style={{ width: '100%', marginBottom: 40, marginTop: 10 }}
								sx={{
									width: { xs: '100%', sm: '100%', md: '60%', lg: '40%', xl: '30%' },
									padding: { xs: '10px', sm: '20px', md: '30px', lg: '40px', xl: '50px' },
									marginLeft: { xs: '0px', sm: 'px', md: '20px', lg: '30px', xl: '40px' }
								}}
							>
								<form onSubmit={handleSubmit(fetchTrackerRecord)}>
									<Grid container direction={'row'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography
												id={createElementId([
													TRACKER,
													PAPER,
													FORM_CONTROL,
													LABEL,
													'trackingLabel'
												])}
												variant="h6"
											>
												Please enter your tracking number:
											</Typography>
										</Grid>
										<Grid xs={12} p={1}>
											<TextField
												id={createElementId([
													TRACKER,
													PAPER,
													FORM_CONTROL,
													TEXTFIELD,
													'trackingId'
												])}
												{...register('trackingId', {
													required: 'Required',
													minLength: {
														value: 19,
														message: 'Code must at least be 19 digits'
													},
													maxLength: {
														value: 36,
														message: 'Code must not exceed 36 digits'
													}
												})}
												required
												label="Tracking Number"
												fullWidth
												inputProps={{ 'data-hj-allow': true }}
												onChange={(e: any) => {
													setTextTrackingId(e.target.value);
												}}
												value={textTrackingId}
												error={!!errors?.trackingId}
												helperText={
													errors?.trackingId ? (errors.trackingId.message as string) : null
												}
											/>
										</Grid>
										<Grid>
											<Button
												id={createElementId([
													TRACKER,
													PAPER,
													FORM_CONTROL,
													BUTTON,
													'trackButton'
												])}
												sx={{
													backgroundColor: '#019636',
													':hover': {
														backgroundColor: '#027b2c'
													},
													whiteSpace: 'nowrap',
													marginRight: '10px',
													size: 'large'
												}}
												color="primary"
												variant="contained"
												size="small"
												onClick={() => {
													fetchTrackerRecord(textTrackingId);
												}}
											>
												Track
											</Button>
										</Grid>
									</Grid>
								</form>
							</Paper>
						</Grid>
					</Grid>
				)}

				{/* Tracker Section */}
				{!loading && tracker && (
					<Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								sx={{
									paddingBottom: '10px',
									border: 0,
									borderBottom: 1,
									borderColor: '#dedede'
								}}
							>
								<Grid container xs={6} sm={6} md={6} lg={6} xl={6} p={2} spacing={1}>
									<Grid item xs={12}>
										<Typography
											id={createElementId([TRACKER, LABEL, 'trackingNumber'])}
											variant="h6"
										>
											Tracking Number
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography
											id={createElementId([TRACKER, LABEL, 'carrierTrackCode'])}
											variant="body1"
										>
											{carrierTrackCode}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
							<Grid sx={{ paddingBottom: '30px', paddingTop: '30px' }}>
								<Stepper orientation="horizontal" alternativeLabel activeStep={activeStep}>
									{steps.map(label => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Grid>
						</Grid>
						<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
							<Alert severity="info" style={{ width: '100%' }}>
								<AlertTitle>
									<strong>Tracking Delivery Time</strong>
								</AlertTitle>
								Estimated delivery time: 10 - 14 days from parcel receipt at the TUNL warehouse.
							</Alert>
						</Grid>
						<Grid xs={12} sm={12} md={12} lg={12} xl={12} marginBottom="80px" sx={{ paddingTop: '30px' }}>
							<Grid alignSelf="start" pl={2}>
								<Typography
									id={createElementId([TRACKER, LABEL, 'trackingUpdates'])}
									variant="h6"
									marginBottom="10px"
								>
									Tracking Updates
								</Typography>
							</Grid>
							<Grid>
								<Grid>
									{tracker !== undefined && tracker !== null && tracker.length > 0 ? (
										tracker.map((trackingDetail: any) => (
											<TrackingDetail trackingDetails={trackingDetail} />
										))
									) : (
										<TNAlerts
											severity="error"
											title="Tracking Details Not Available"
											message="Tracking details for this shipment are not yet available. Please try again later."
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>

			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 2 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={4000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
