import * as yup from 'yup';
import { YupError, YupValidatorResult } from '../../../../types/yupError';
class CSVRowValidator {
	public static nonEmptyValidation = (schema: yup.StringSchema, fieldName: string) =>
		schema.test('not-empty', `${fieldName} cannot be empty`, value => value !== '');

	public static shipmentSchema = yup.array().of(
		yup.object().shape({
			order_number: CSVRowValidator.nonEmptyValidation(
				yup.string().required('Order Number is required'),
				'Order Number'
			),
			address_to_name: yup.string().required('Address To Name is required'),
			address_to_company: yup.string().nullable(),
			address_to_street_1: yup
				.string()
				.required('Address To Street 1 is required')
				.test(
					'no-po-box',
					'PO Box addresses are not allowed. Please request another address from the customer.',
					value => !value || !/p\.?\s?o\.?\s?box/i.test(value)
				),
			address_to_street_2: yup
				.string()
				.nullable()
				.test(
					'no-po-box',
					'PO Box addresses are not allowed. Please request another address from the customer.',
					value => !value || !/p\.?\s?o\.?\s?box/i.test(value)
				),
			address_to_city_locality: yup.string().required('Address To City Locality is required'),
			address_to_state_province: yup.string().required('Address To State Province is required'),
			address_to_zip_postal: yup.string().required('Address To Zip Postal is required'),
			address_to_country: yup.string().required('Address To Country is required'),
			address_to_phone: yup.string().required('Address To Phone is required'),
			address_to_email: yup.string().required('Address To Email is required'),
			parcel_length_cm: yup
				.number()
				.integer('Parcel length must be an integer')
				.positive('Parcel length must be a positive number')
				.min(1, 'Parcel length must be greater than 1cm')
				.max(170, 'Parcel length must be less than 170cm')
				.required('Parcel length is required'),
			parcel_width_cm: yup
				.number()
				.integer('Parcel width must be an integer')
				.positive('Parcel width must be a positive number')
				.min(1, 'Parcel width must be greater than 1cm')
				.max(100, 'Parcel width must be less than 100cm')
				.required('Parcel width is required'),
			parcel_height_cm: yup
				.number()
				.integer('Parcel height must be an integer')
				.positive('Parcel height must be a positive number')
				.min(1, 'Parcel height must be greater than 1cm')
				.max(100, 'Parcel height must be less than 100cm')
				.required('Parcel height is required'),
			parcel_weight_kg: yup
				.number()
				.positive('Parcel weight must be a positive number')
				.min(0.1, 'Parcel weight must be greater than 0.1Kg')
				.max(70, 'Parcel weight must be less than 70Kg')
				.required('Parcel weight is required')
				.test(
					'volumetric-weight-check',
					'Volumetric weight cannot be greater than parcel weight (Chargeable weight)',
					function (parcel_weight_kg) {
						const { parcel_length_cm, parcel_width_cm, parcel_height_cm } = this.parent; // Access parent fields
						if (!parcel_length_cm || !parcel_width_cm || !parcel_height_cm || !parcel_weight_kg)
							return true; // Skip if any dimension is missing
						const dimensionalWeight = (parcel_length_cm * parcel_width_cm * parcel_height_cm) / 5000;
						return dimensionalWeight < parcel_weight_kg;
					}
				),

			service: yup
				.string()
				.required('Shipping Service is required')
				.oneOf(
					['FEDEX_INTERNATIONAL_PRIORITY', 'UPSSaver', 'Ground'],
					'The Shipping Service must be one of the following: FEDEX_INTERNATIONAL_PRIORITY or UPS_SAVER or TUNL_ECONOMY'
				),
			shipping_type: yup
				.string()
				.required('Shipping Type is required')
				.oneOf(['DAP', 'DDP'], 'The Shipping Type must be one of the following: DAP or DDP'),
			to_return: yup.boolean().required('Return is required'),
			insurance: yup.number().nullable().min(0, 'Insurance value must be 0 or greater'),
			invoice: yup.string().required('Insurance is required'),
			product_reference: yup.string().nullable(),
			content_type: yup
				.string()
				.required('Content Type is required')
				.oneOf(['Merchandise'], 'The Content Type must be: Merchandise'),
			signature: yup
				.string()
				.required('Shipping Type is required')
				.oneOf(['true', 'false'], 'The Shipping Type must be one of the following: TRUE or FALSE'),
			customs_info: yup.object().shape({
				contents_type: yup.string().required('Contents Type is required'),
				customs_items: yup
					.array()
					.of(
						yup.object().shape({
							description: yup.string().required('Description is required'),
							hs_tariff_number: yup.string().required('HS Tariff Number is required'),
							quantity: yup
								.number()
								.required('Quantity is required')
								.integer('Quantity must be an integer')
								.min(1, 'Quantity must be at least 1'),
							weight: yup
								.number()
								.required('Weight is required')
								.min(0.01, 'Weight must be greater than 0'),
							value: yup.number().required('Value is required').min(0, 'Value must be at least 0'),
							currency: yup
								.string()
								.required('Currency is required')
								.length(3, 'Currency code must be 3 characters long'),
							origin_country: yup
								.string()
								.required('Origin country is required')
								.length(2, 'Origin country must be a 2-letter ISO code')
						})
					)
					.required('At least one customs item is required')
					.test('same-currency', 'All customs items must have the same currency', customs_items => {
						if (!customs_items || customs_items.length === 0) return true;
						const firstCurrency = customs_items[0]?.currency;
						return customs_items.every(item => item.currency === firstCurrency);
					})
					.test(
						'weight-check',
						'The parcel chargeable weight must be greater than the sum of the weights of the customs items',
						function (customs_items) {
							const { parcel_weight_kg } = this.parent; // Access the parent object
							if (!parcel_weight_kg || !customs_items) return true; // Skip if no data
							const totalCustomsWeight = customs_items.reduce((sum, item) => sum + (item.weight || 0), 0);
							return parcel_weight_kg > totalCustomsWeight;
						}
					)
					.test(
						'no-duplicate-hs-tariff-number',
						'Duplicate HS Tariff Numbers are not allowed',
						function (customs_items) {
							if (!customs_items) return true; // Skip if no customs items
							const uniqueHsTariffNumbers = new Set(customs_items.map(item => item.hs_tariff_number));
							return uniqueHsTariffNumbers.size === customs_items.length;
						}
					)
			}),

			date_created: yup.string().nullable()
		})
	);

	// Method to validate the list
	public static validateShipmentSchema = async (data: any[]): Promise<YupValidatorResult> => {
		try {
			await CSVRowValidator.shipmentSchema.validate(data, { abortEarly: false });
			return { isValid: true, errors: null };
		} catch (err) {
			if (err instanceof yup.ValidationError) {
				const errors: Array<YupError> = err.inner.map(error => ({
					path: error.path,
					message: error.message
				}));
				return { isValid: false, errors };
			}
			throw err;
		}
	};
}

export default CSVRowValidator;
