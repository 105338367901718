import { AppBar, Box, Grid, Link, styled, Typography } from '@mui/material';
import theme from '../../util/theme';

const Img = styled('img')({
	display: 'block',
	maxWidth: '160px',
	maxHeight: '160px'
});

export default function TrackerFooter() {
	return (
		<Box
			component="footer"
			sx={{
				px: 2,
				position: 'fixed',
				zIndex: theme => theme.zIndex.drawer + 1,
				backgroundColor: '#030303',
				color: '#ffffff',
				left: 0,
				bottom: 0,
				width: '100%'
			}}
		>
			<Grid container alignItems="center" justifyContent="space-between" direction={'row'}>
				<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
					<Img src="../img/tunl-logo-cream.png" />
				</Grid>
				<Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
				<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
					<Link href="mailto:support@tunl.to" color="inherit" underline="always">
						<Typography textAlign="right">support@tunl.to</Typography>
					</Link>
					<Link href="https://help.tunl.to/" color="inherit" underline="always" target="_blank">
						<Typography textAlign="right">help.tunl.to</Typography>
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
}
