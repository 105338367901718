import {
	Add,
	CheckCircleOutline,
	KeyboardArrowDown,
	KeyboardArrowUp,
	RadioButtonUncheckedOutlined,
	Remove
} from '@mui/icons-material';
import {
	Alert,
	Autocomplete,
	Backdrop,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Divider,
	Snackbar,
	TextField,
	Typography,
	Box,
	MenuItem
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SnackAlert } from '../../types/util';
import { Currency } from '../../types/currency';

export default function ShipmentReviewComponent(props: any) {
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const {
		register,
		errors,
		shipmentDetails,
		deliveryAddress,
		handleNext,
		handlePrevious,
		handleUpdate,
		pdf,
		currentShipment,
		numShipments,
		shipments,
		handleSearch,
		handleAdd,
		handleRemove,
		handleRemoveLine,
		instance,
		handleCurrencyChange,
		selectedCurrency
	} = props;

	const [numPages, setNumPages] = useState<any>(null);
	const [pdfImage, setPdfImage] = useState<string | null>(null);
	const [scale, setScale] = useState(1.15);
	const [magnifierStyle, setMagnifierStyle] = useState({
		display: 'none',
		left: '',
		top: '',
		backgroundPosition: '0 0',
		backgroundImage: '',
		transform: `scale(${scale})`,
		backgroundSize: 'auto'
	});
	const pdfContainerRef = useRef<HTMLDivElement>(null);
	const [pdfImageURL, setPdfImageURL] = useState('');
	const animationFrameId = useRef<number | null>(null);
	const currencyList: Currency[] = ['USD', 'ZAR', 'EUR', 'CAD', 'GBP', 'AUD'];

	useEffect(() => {
		const convertToImage = async () => {
			try {
				setPdfImageURL(!shipments[currentShipment]?.order_comm_invoice ? instance.url : pdf);
				if (!pdfImageURL) throw new Error('No PDF URL provided');

				const loadingTask = pdfjs.getDocument(pdfImageURL);
				const pdfFile = await loadingTask.promise;
				const page = await pdfFile.getPage(pageNumber);
				const viewport = page.getViewport({ scale: scale * 2 });
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				if (context) {
					const renderContext = {
						canvasContext: context,
						viewport: viewport
					};
					await page.render(renderContext).promise;
					setPdfImage(canvas.toDataURL());
				} else {
					throw new Error('Failed to get canvas context');
				}
			} catch (error) {
				console.error('Error converting PDF to image:', error);
			}
		};

		convertToImage();
	}, [instance, pdfImageURL, pageNumber, scale, pdf]);

	const onDocumentLoadSuccess = (pdfInfo: any) => {
		setPageNumber(1);
		setNumPages(pdfInfo.numPages);
	};

	const handleOpen = (event: any) => {
		setOpenPopover(true);
		setAnchorEl(event.currentTarget);
		setTimeout(() => {
			setOpenPopover(false);
		}, 500);
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowDown') {
			handleNext();
		} else if (event.key === 'ArrowUp') {
			handlePrevious();
		}
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const handleMouseMove = (event: React.MouseEvent) => {
		const container = pdfContainerRef.current;
		if (!container) return;

		const { left, top, width, height } = container.getBoundingClientRect();
		const x = event.clientX - left;
		const y = event.clientY - top;

		const magnifierSize = 200; // Size of the magnifier
		const zoomFactor = scale; // Zoom factor for the magnifier

		// Ensure the magnifier stays within the bounds of the container
		const magnifierLeft = Math.max(0, Math.min(x - magnifierSize / 2, width - magnifierSize));
		const magnifierTop = Math.max(0, Math.min(y - magnifierSize / 2, height - magnifierSize));

		// Ensure the background position stays within the bounds of the image
		const backgroundPosX = Math.max(
			0,
			Math.min(x * zoomFactor - magnifierSize / 2, width * zoomFactor - magnifierSize)
		);
		const backgroundPosY = Math.max(
			0,
			Math.min(y * zoomFactor - magnifierSize / 2, height * zoomFactor - magnifierSize)
		);

		// Throttle updates using requestAnimationFrame
		if (!animationFrameId.current) {
			animationFrameId.current = requestAnimationFrame(() => {
				setMagnifierStyle({
					display: 'block',
					left: `${magnifierLeft}px`, // Center the magnifier horizontally
					top: `${magnifierTop}px`, // Center the magnifier vertically
					backgroundPosition: `-${backgroundPosX}px -${backgroundPosY}px`, // Adjust based on new width and height
					backgroundImage: `url(${pdfImage})`,
					transform: `scale(${scale})`,
					backgroundSize: `${width * zoomFactor}px ${height * zoomFactor}px` // Adjust based on new width and height
				});
				animationFrameId.current = null;
			});
		}
	};

	const handleMouseLeave = () => {
		setMagnifierStyle({ ...magnifierStyle, display: 'none' });
		if (animationFrameId.current) {
			cancelAnimationFrame(animationFrameId.current);
			animationFrameId.current = null;
		}
	};

	useEffect(() => {
		return () => {
			if (animationFrameId.current) {
				cancelAnimationFrame(animationFrameId.current);
			}
		};
	}, []);

	return (
		<>
			<Grid container direction={'row'} alignItems={'center'} spacing={2}>
				<Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<style>
						{`
						.pdf-container {
							position: relative;
							overflow: hidden;
							cursor: none;
						}

						.pdf-magnifier {
							position: absolute;
							width: 200px; /* Adjust the width */
							height: 200px; /* Adjust the height */
							border-radius: 2%;
							pointer-events: none;
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
							background-repeat: no-repeat;
							transform: scale(${scale});
						}

						.pdf-container:hover .pdf-magnifier {
							transform: scale(${scale}); /* Adjust the scale for zoom effect */
						}
						`}
					</style>
					<div
						className="pdf-container"
						ref={pdfContainerRef}
						onMouseMove={handleMouseMove}
						onMouseLeave={handleMouseLeave}
						style={{ position: 'relative', overflow: 'hidden' }}
					>
						<Document file={pdfImageURL} onLoadSuccess={onDocumentLoadSuccess}>
							<Page pageNumber={pageNumber} height={590} width={570} />
						</Document>
						<div className="pdf-magnifier" style={magnifierStyle}></div>
					</div>
					<Grid
						container
						direction="row"
						alignItems={'center'}
						justifyContent={'space-around'}
						sx={{
							xs: 12,
							sm: 12,
							md: 12,
							lg: 12,
							xl: 12
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))}
							disabled={pageNumber <= 1}
						>
							Previous Page
						</Button>
						<Typography variant="h6">
							Page {pageNumber} of {numPages}
						</Typography>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages))}
							disabled={pageNumber >= numPages}
						>
							Next Page
						</Button>
					</Grid>
				</Grid>
				<Grid size="grow" spacing={2}>
					<form>
						<Grid
							container
							direction={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
							spacing={2}
						>
							<Grid size={{ xs: 12, sm: 11, md: 11, lg: 11, xl: 11 }}>
								<Autocomplete
									id="filter-demo"
									options={shipments}
									getOptionLabel={(option: any) => option.customs_hawb_number}
									defaultValue={shipments[currentShipment]}
									onChange={(event: any, newValue: any) => {
										if (shipments.indexOf(newValue) !== -1) {
											handleSearch(shipments.indexOf(newValue));
										}
									}}
									renderInput={params => <TextField {...params} label="HAWB Number" />}
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 1, md: 1, lg: 1, xl: 1 }} justifyContent="center">
								{shipmentDetails.shipment_edit_status.toLowerCase() === 'edited' ? (
									<CheckCircleOutline sx={{ color: '#17ff35' }} />
								) : (
									<RadioButtonUncheckedOutlined sx={{ color: '#007BC4' }} />
								)}
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
								<TextField
									label="Invoice Number"
									{...register('shipment_reference', {})}
									error={!!errors?.parcel_details?.shipment_reference}
									helperText={
										errors?.parcel_details?.shipment_reference
											? (errors.parcel_details.shipment_reference.message as string)
											: null
									}
									fullWidth
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
							<Grid direction={'row'} size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
								<TextField
									label="Destination Address"
									value={deliveryAddress}
									fullWidth
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
							<Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
								<Paper
									elevation={2}
									sx={{
										backgroundColor: 'rgb(245, 245, 245)',
										overflow: 'auto',
										minHeight: '20vh',
										maxHeight: '59vh'
									}}
								>
									<Box p={2}>
										<Grid
											container
											direction={'row'}
											alignItems={'center'}
											justifyContent={'center'}
										>
											{shipmentDetails.customs_info?.customs_items?.map(
												(customs_item: any, index: number) => (
													<React.Fragment key={index}>
														<Grid direction={'row'} mt={2} mb={2}>
															<Typography variant="h5" align="center" fontWeight={'bold'}>
																Customs Item {index + 1}
															</Typography>
														</Grid>
														<Grid
															container
															direction={'row'}
															spacing={2}
															justifyContent={'space-between'}
														>
															<Grid size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
																<TextField
																	required
																	label="Description"
																	{...register(
																		`customs_info.customs_items.${index}.description`,
																		{
																			required: 'Required'
																		}
																	)}
																	error={
																		!!errors?.customs_info?.customs_items?.[index]
																			?.description
																	}
																	helperText={
																		errors?.customs_info?.customs_items?.[index]
																			?.description
																			? (errors.customs_info.customs_items[index]
																					.description.message as string)
																			: null
																	}
																	fullWidth
																/>
															</Grid>
															<Grid size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
																<TextField
																	required
																	label="Arrival HTS Code"
																	{...register(
																		`customs_info.customs_items.${index}.hs_tariff_number`,
																		{
																			required: 'Required'
																		}
																	)}
																	error={
																		!!errors?.customs_info?.customs_items?.[index]
																			?.hs_tariff_number
																	}
																	helperText={
																		errors?.customs_info?.customs_items?.[index]
																			?.hs_tariff_number
																			? (errors.customs_info.customs_items[index]
																					.hs_tariff_number.message as string)
																			: null
																	}
																	fullWidth
																/>
															</Grid>
															<Grid size={{ xs: 12, sm: 2.4, md: 2.4, lg: 2.4, xl: 2.4 }}>
																<TextField
																	required
																	label="Quantity"
																	{...register(
																		`customs_info.customs_items.${index}.quantity`,
																		{
																			required: 'Required',
																			valueAsNumber: true
																		}
																	)}
																	error={
																		!!errors?.customs_info?.customs_items?.[index]
																			?.quantity
																	}
																	helperText={
																		errors?.customs_info?.customs_items?.[index]
																			?.quantity
																			? (errors.customs_info.customs_items[index]
																					.quantity.message as string)
																			: null
																	}
																	fullWidth
																/>
															</Grid>
															<Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
																<TextField
																	required
																	label="Origin Country"
																	{...register(
																		`customs_info.customs_items.${index}.origin_country`,
																		{
																			required: 'Required'
																		}
																	)}
																	error={
																		!!errors?.customs_info?.customs_items?.[index]
																			?.origin_country
																	}
																	helperText={
																		errors?.customs_info?.customs_items?.[index]
																			?.origin_country
																			? (errors.customs_info.customs_items[index]
																					.origin_country.message as string)
																			: null
																	}
																	fullWidth
																/>
															</Grid>
															<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
																<TextField
																	{...register(
																		`customs_info.customs_items.${index}.currency`,
																		{}
																	)}
																	select
																	label="Currency"
																	placeholder="Select Currency"
																	fullWidth
																	value={selectedCurrency}
																	onChange={(e: any) =>
																		handleCurrencyChange(index, e.target.value)
																	}
																>
																	{currencyList.map((currency: string) => (
																		<MenuItem key={currency} value={currency}>
																			{currency}
																		</MenuItem>
																	))}
																</TextField>
															</Grid>
															<Grid size={{ xs: 12, sm: 3.6, md: 3.6, lg: 3.6, xl: 3.6 }}>
																<TextField
																	required
																	label="Total Value"
																	{...register(
																		`customs_info.customs_items.${index}.value`,
																		{
																			required: 'Required',
																			valueAsNumber: true
																		}
																	)}
																	error={
																		!!errors?.customs_info?.customs_items?.[index]
																			?.value
																	}
																	helperText={
																		errors?.customs_info?.customs_items?.[index]
																			?.value
																			? (errors.customs_info.customs_items[index]
																					.value.message as string)
																			: null
																	}
																	fullWidth
																/>
															</Grid>
															<Grid
																container
																direction={'row'}
																size={{ xs: 12, sm: 1, md: 1, lg: 1, xl: 1 }}
																alignItems={'center'}
																justifyContent={'center'}
															>
																<IconButton
																	sx={{ mt: 2 }}
																	onClick={() => handleRemoveLine(index)}
																	size="small"
																>
																	<Remove />
																</IconButton>
															</Grid>
														</Grid>
														<Divider
															style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
														/>
													</React.Fragment>
												)
											)}
										</Grid>
									</Box>
								</Paper>
							</Grid>
						</Grid>
						<Grid container alignContent="center" justifyContent="center" direction="column">
							<Grid container direction="row" alignContent="center" justifyContent="center" mt={2}>
								<Grid>
									<IconButton onClick={() => handleAdd()}>
										<Add></Add>
									</IconButton>
								</Grid>
								<Grid>
									<IconButton
										onClick={() => {
											handleRemove();
										}}
									>
										<Remove></Remove>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid mt={2}>
							<Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
								<Button
									disabled={Object.keys(errors).length ? true : false}
									color="primary"
									variant="contained"
									onClick={handleUpdate}
								>
									Update Shipment
								</Button>
							</Grid>
						</Grid>
						<p>{'Shipment : ' + (currentShipment + 1) + ' of ' + numShipments} </p>
					</form>
				</Grid>
				<Grid size={{ xs: 12, sm: 12, md: 1, lg: 1, xl: 1 }} alignItems={'center'} justifyContent={'center'}>
					<Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
						<Grid>
							<IconButton aria-label="expand row" size="large" onClick={handlePrevious}>
								<KeyboardArrowUp />
							</IconButton>
						</Grid>
						<Grid>
							<IconButton aria-label="expand row" size="large" onClick={handleNext}>
								<KeyboardArrowDown />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
