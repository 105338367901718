import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ShippingMethod } from '../SelectCheckoutShippingMethods';

interface ShippingMethodSelectorProps {
	label: ReactNode;
	checkboxName: string;
	methodName?: string;
	methodValue?: ShippingMethod;
	selected: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	register: UseFormRegister<FieldValues>;
	errors: FieldValues;
	hasOptions?: boolean;
	disabled: boolean;
}

export default function ShippingMethodSelector(props: ShippingMethodSelectorProps) {
	return (
		<FormControl disabled={props.disabled} component="fieldset" fullWidth variant="standard" sx={{ mb: 2 }}>
			<FormControlLabel
				label={props.label}
				control={
					<Checkbox
						checked={props.selected}
						sx={{ color: props.errors.checkboxes ? 'red' : 'grey' }}
						{...props.register(props.checkboxName)}
						onChange={props.onSelect}
					/>
				}
			/>
			{props.hasOptions === false && (
				<Typography pl={3} component="div">
					TUNL Economy rates show only for US orders under $800.
				</Typography>
			)}

			{props.hasOptions && props.methodName && props.onChange && (
				<TextField
					select
					value={props.methodValue}
					fullWidth
					variant="outlined"
					{...props.register(props.methodName)}
					disabled={props.disabled || !props.selected}
					onChange={props.onChange}
				>
					{/* <MenuItem disabled value={ShippingMethod.NONE}>
						Please Select Shipping Method
					</MenuItem> */}
					<MenuItem value={ShippingMethod.DDP}>Duties & Taxes Included</MenuItem>
					<MenuItem value={ShippingMethod.DAP}>Duties & Taxes Not Included</MenuItem>
					<MenuItem value={ShippingMethod.BOTH}>Both</MenuItem>
				</TextField>
			)}
		</FormControl>
	);
}
