import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { pdfjs } from 'react-pdf';
import { Navigate, useLocation, useNavigationType } from 'react-router-dom';
import ShipmentReviewComponent from '../../components/Customs/ShipmentReviewComponent';
import { axiosConfig } from '../../constants/axios';
import { Shipment } from '../../types/shipment';
import { usePDF } from '@react-pdf/renderer';
import GenerateCommercialInvoice from '../../components/Merchant/Shipment/GenerateCommercialInvoice';
import { Currency } from '../../types/currency';

export default function ShipmentReview(props: any) {
	const merchant = JSON.parse(sessionStorage.getItem('merchant') as string);
	const location = useLocation();
	const navType = useNavigationType();
	const incomingShipments: any = location.state;
	const [pdf, setPdf] = useState('' as string | ArrayBuffer | null);
	const [loading, setLoading] = useState(false);
	const [deliveryAddress, setDeliveryAddress] = useState('');
	pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [currentShipment, setCurrentShipment] = useState(0);
	const [numShipments, setNumShipments] = useState(incomingShipments.length);
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const [shipmentDetails, setShipmentDetails] = useState<Shipment[]>(
		incomingShipments.sort((a: Shipment, b: Shipment) => (a.customs_hawb_number > b.customs_hawb_number ? 1 : -1))
	);
	const [instance, setInstance] = usePDF({
		document: <GenerateCommercialInvoice shipment={shipmentDetails[currentShipment]} merchant={merchant} />
	});
	const axiosInstance = axiosConfig();
	const handleUpdate = () => {
		setValue('shipment_edit_status', 'edited');
		setLoading(true);
		axiosInstance
			.post('/shipment/updateShipment', getValues())
			.then(response => {
				shipmentDetails[currentShipment] = response.data.data;
				setLoading(false);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	};

	const {
		register,
		formState: { errors },
		setValue,
		getValues,
		reset,
		control,
		trigger
	} = useForm({
		mode: 'all',
		defaultValues: {
			...shipmentDetails[currentShipment]
		}
	});

	const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
		shipmentDetails[currentShipment]?.customs_info?.customs_items[0]?.currency || 'USD'
	);

	const handleCurrencyChange = (index: number, value: Currency) => {
		setValue(`customs_info.customs_items.${index}.currency`, value);
		const items = getValues('customs_info.customs_items');
		items.forEach((item: any, i: number) => {
			setValue(`customs_info.customs_items.${i}.currency`, value);
		});
		setSelectedCurrency(value);
	};

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'customs_info.customs_items'
	});

	const handleAdd = () => {
		append({
			currency: selectedCurrency as Currency,
			hs_tariff_number: '',
			weight: 0,
			value: 0,
			quantity: 0,
			origin_country: 'ZA',
			description: ''
		});
		shipmentDetails[currentShipment].customs_info.customs_items.push({
			currency: selectedCurrency as Currency,
			hs_tariff_number: '',
			weight: 0,
			value: 0,
			quantity: 0,
			origin_country: 'ZA',
			description: ''
		});
	};

	const handleRemove = () => {
		if (shipmentDetails[currentShipment].customs_info.customs_items.length > 1) {
			remove(fields.length - 1);
			shipmentDetails[currentShipment].customs_info.customs_items.pop();
		}
	};

	const handleRemoveLine = (index: number) => {
		if (shipmentDetails[currentShipment].customs_info.customs_items.length > 1) {
			remove(index);
			shipmentDetails[currentShipment].customs_info.customs_items.splice(index, 1);
		}
	};

	const handleNext = () => {
		setCurrentShipment(currentShipment + 1);
		if (currentShipment === shipmentDetails.length - 1) {
			setCurrentShipment(0);
		}
		reset({});
		setFormValues();
	};

	const handlePrevious = () => {
		setCurrentShipment(currentShipment - 1);
		if (currentShipment === 0) {
			setCurrentShipment(shipmentDetails.length - 1);
		}
		reset({});
		setFormValues();
	};

	const handleSearch = (index: number) => {
		setCurrentShipment(index);
		reset({});
		setFormValues();
	};

	const setFormValues = () => {
		setDeliveryAddress(
			shipmentDetails[currentShipment].address_to_name +
				', ' +
				shipmentDetails[currentShipment].address_to_street_1 +
				', ' +
				shipmentDetails[currentShipment].address_to_city_locality +
				', ' +
				shipmentDetails[currentShipment].address_to_state_province +
				', ' +
				shipmentDetails[currentShipment].address_to_zip_postal +
				', ' +
				shipmentDetails[currentShipment].address_to_country
		);
		setValue('partitionKey', shipmentDetails[currentShipment].partitionKey);
		setValue('sortKey', shipmentDetails[currentShipment].sortKey);
		setValue('address_to_city_locality', shipmentDetails[currentShipment].address_to_city_locality);
		setValue('address_to_company', shipmentDetails[currentShipment].address_to_company);
		setValue('address_to_country', shipmentDetails[currentShipment].address_to_country);
		setValue('address_to_email', shipmentDetails[currentShipment].address_to_email);
		setValue('address_to_google_search', shipmentDetails[currentShipment].address_to_google_search);
		setValue('address_to_id', shipmentDetails[currentShipment].address_to_id);
		setValue('address_to_name', shipmentDetails[currentShipment].address_to_name);
		setValue('address_to_phone', shipmentDetails[currentShipment].address_to_phone);
		setValue('address_to_state_province', shipmentDetails[currentShipment].address_to_state_province);
		setValue('address_to_street_1', shipmentDetails[currentShipment].address_to_street_1);
		setValue('address_to_street_2', shipmentDetails[currentShipment].address_to_street_2);
		setValue('address_to_zip_postal', shipmentDetails[currentShipment].address_to_zip_postal);
		setValue('order_comm_invoice', shipmentDetails[currentShipment].order_comm_invoice);
		setValue('shipment_id', shipmentDetails[currentShipment].shipment_id);
		setValue('shipment_reference', shipmentDetails[currentShipment].shipment_reference);
		setValue('customs_info', shipmentDetails[currentShipment].customs_info);
	};

	const getPDF = (pdfUrl: string) => {
		setLoading(true);
		axios
			.get(pdfUrl, {
				responseType: 'arraybuffer'
			})
			.then(response => {
				const base64String = Buffer.from(response.data, 'binary').toString('base64');
				setPdf('data:application/pdf;base64,' + base64String);
				setLoading(false);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		setLoading(true);
		const getShipmentsByBookingId = async () => {
			try {
				const response = await axiosInstance.post('/shipment/getShipmentsBookingID', {
					booking_id: incomingShipments[0].booking_id
				});
				setShipmentDetails(response.data.data);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		};

		if (navType === 'POP') getShipmentsByBookingId();
		else setLoading(false);

		setNumShipments(incomingShipments.length);
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	useEffect(() => {
		reset();
		setFormValues();
		getPDF(shipmentDetails[currentShipment].order_comm_invoice as string);
		setInstance(<GenerateCommercialInvoice shipment={shipmentDetails[currentShipment]} merchant={merchant} />);
		setSelectedCurrency(
			shipmentDetails[currentShipment]?.customs_info?.customs_items[0]?.currency || 'USD'
		);
	}, [shipmentDetails[currentShipment], instance]);

	return (
		<>
			{!user.admin_access ? (
				<Navigate to="/" state={{ type: 'error', message: 'Access Denied!' }} />
			) : (
				<div>
					{!loading ? (
						<ShipmentReviewComponent
							register={register}
							errors={errors}
							shipmentDetails={shipmentDetails[currentShipment]}
							deliveryAddress={deliveryAddress}
							handleNext={handleNext}
							handlePrevious={handlePrevious}
							handleUpdate={handleUpdate}
							getValues={getValues}
							pdf={pdf}
							currentShipment={currentShipment}
							numShipments={numShipments}
							shipments={shipmentDetails}
							handleSearch={handleSearch}
							handleAdd={handleAdd}
							handleRemove={handleRemove}
							fields={fields}
							handleRemoveLine={handleRemoveLine}
							instance={instance}
							handleCurrencyChange={handleCurrencyChange}
							selectedCurrency={selectedCurrency}
						/>
					) : (
						<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
				</div>
			)}
		</>
	);
}
