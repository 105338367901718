import { Menu, Storefront } from '@mui/icons-material';
import {
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Modal,
	Toolbar,
	Tooltip,
	Typography,
	styled
} from '@mui/material';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MerchantSelect from '../../components/Merchant/MerchantSelect';
import { BUTTON, GUIDED_TOUR, HEADER, createElementId } from '../../constants/id';
import { useMerchant } from '../../context/MerchantContext';
import { setAlertBoolean } from '../../stores/alert';
import { Sidebar } from './Sidebar';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	backgroundColor: theme.palette.primary.main,
	justifyContent: 'space-between',
	height: 60,
	position: 'fixed',
	boxShadow: 'none'
}));

export default function Header(props: any) {
	const path = window.location.pathname.split('/');
	const { merchantProfile, availableMerchants } = useMerchant();
	const { isXsSmMd } = props;
	const [selectedIndex, setSelectedIndex] = useState<number>();
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isSidebarOpen, setSidebarOpen] = useState(isXsSmMd ? true : false);
	const showNavigation = props.showNavigation;

	useEffect(() => {
		if (!!path && path[1]?.toLowerCase() === 'customs')
			setSelectedIndex(path[2] === 'booking' ? 1 : path[2] === 'shipments' ? 2 : 0);
		else setSelectedIndex(path[1] === 'shipment' ? 1 : path[1] === 'profile' ? 2 : 0);
	}, []);

	useEffect(() => {
		setSidebarOpen(isXsSmMd ? true : false);
	}, [isXsSmMd]);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const tourText = isXsSmMd ? 'Get Started with a Tour' : 'Start Tour';
	if (pathname === '/shipment/details' || pathname === '/shipment/details') {
		dispatch(setAlertBoolean('false'));
	}
	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3
	};

	const leavePage = () => {
		dispatch(setAlertBoolean('false'));
		setModalOpen(false);
		setLoading(true);
		if (selectedIndex === 8) {
			sessionStorage.clear();
			props.signOut();
		}
		selectedIndex === 0
			? navigate('/')
			: selectedIndex === 1
			? navigate('/shipment')
			: selectedIndex === 2
			? navigate('/products')
			: selectedIndex === 3
			? navigate('/profile')
			: selectedIndex === 4
			? navigate('/users')
			: selectedIndex === 5
			? navigate('/live-rates')
			: navigate('/');
		setLoading(false);
	};

	const handleDrawerOpen = () => {
		setSidebarOpen(true);
	};

	const handleDrawerClose = () => {
		setSidebarOpen(false);
	};

	const { signOut } = useAuthenticator(context => [context.user]);

	const signUserOut = () => {
		sessionStorage.clear();
		signOut();
	};

	return (
		<>
			<StyledAppBar>
				<Toolbar disableGutters={isXsSmMd}>
					{!isXsSmMd && showNavigation && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={isSidebarOpen ? handleDrawerClose : handleDrawerOpen}
							sx={{ mr: 2 }}
							size="large"
						>
							<Menu color="secondary" />
						</IconButton>
					)}
					{showNavigation && (
						<>
							{availableMerchants.length === 1 ? (
								<Box
									sx={{ display: 'flex', flexDirection: 'row' }}
									pl={1}
									justifyItems={'center'}
									alignItems={'center'}
								>
									<Storefront sx={{ 'min-width': '35px' }} />
									<Typography variant="h5">{merchantProfile.merchant_name}</Typography>
								</Box>
							) : (
								<MerchantSelect
									sx={{
										width: '220px',
										backgroundColor: '#f5f5f5',
										borderRadius: '10px',
										border: '1px solid #ddd',
										padding: '5px',
										ml: 2
									}}
								/>
							)}
						</>
					)}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: 'auto',
							height: '100%'
						}}
					>
						{pathname === '/' && showNavigation && isXsSmMd && (
							<Tooltip title={'New here? Take a quick tour to learn how to book your first shipment!'}>
								<Button
									id={createElementId([HEADER, GUIDED_TOUR, BUTTON, 'start-tour'])}
									variant="outlined"
									color="secondary"
									sx={{
										fontWeight: 'bold',
										borderRadius: '20px',
										backgroundColor: '#ACF381',
										color: '#007BC4',
										borderColor: '#007BC4',
										':hover': {
											backgroundColor: '#007BC4',
											color: '#ACF381',
											borderColor: '#ACF381'
										}
									}}
								>
									{tourText}
								</Button>
							</Tooltip>
						)}
						{isXsSmMd && (
							<Box
								component="img"
								src="../img/tunl-logo-cream.png"
								alt="Tunl Logo"
								sx={{
									maxWidth: '170px'
								}}
							/>
						)}
					</Box>
				</Toolbar>
			</StyledAppBar>
			{showNavigation && (
				<Sidebar
					open={isSidebarOpen}
					onOpen={handleDrawerOpen}
					onClose={handleDrawerClose}
					isXsSmMd={isXsSmMd}
					signUserOut={signUserOut}
				/>
			)}
			{modalOpen && (
				<Modal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={resetModalStyle} style={{ border: 'none', boxShadow: '1px 1px solid gray' }}>
						<Typography id="modal-modal-title" variant="h6" color="gray" component="h2">
							Exit form?
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2, color: 'gray', fontSize: '14px' }}>
							Are you sure you want to leave the form? This will clear all shipment details you have
							entered so far.
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Button onClick={() => setModalOpen(false)} sx={{ mr: 1 }} color="primary">
								Stay here
							</Button>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button color="error" sx={{ fontSize: '14px' }} onClick={leavePage}>
								I want to leave
							</Button>
						</Box>
					</Box>
				</Modal>
			)}
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
