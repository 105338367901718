import { MockShipment } from '../../types/mockShipments';

export const parseMockShipmenToCSV = (shipmentsData: MockShipment[]): string[][] => {
	const parseData = shipmentsData.map(shipment => {
		// Flatten customs items into columns
		const customsItems = shipment.customs_info.customs_items
			.map(item => [
				item.description,
				item.hs_tariff_number,
				item.quantity.toString(),
				item.weight.toString(),
				item.value.toString(),
				item.currency
			])
			.flat();

		// Generate a row for the shipment
		return [
			shipment.order_number || '',
			shipment.address_to_name || '',
			shipment.address_to_company,
			shipment.address_to_street_1 || '',
			shipment.address_to_street_2,
			shipment.address_to_city_locality || '',
			shipment.address_to_state_province || '',
			shipment.address_to_zip_postal,
			shipment.address_to_country,
			shipment.address_to_phone,
			shipment.address_to_email,
			shipment.parcel_length_cm.toString(),
			shipment.parcel_width_cm.toString(),
			shipment.parcel_height_cm.toString(),
			shipment.parcel_weight_kg.toString(),
			shipment.service,
			shipment.shipping_type,
			shipment.insurance.toString(),
			shipment.invoice,
			shipment.product_reference,
			shipment.content_type,
			shipment.signature ? 'TRUE' : 'FALSE',
			...customsItems
		];
	});
	return parseData;
};
